import React, { useState } from "react";
import show from "../../../assets/home/show.svg";

export default function Personal({
  handleInputChange,
  ownerFirstName,
  ownerLastName,
  ownerEmail,
  ownerPassword,
}) {

  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className="sign-up-forms-1">
      <div className="sign-up-form-field">
        <div>
          <label>First Name*</label>
          <input
            type="text"
            placeholder="Will"
            required
            name="ownerFirstName"
            value={ownerFirstName}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Last Name*</label>
          <input
            type="text"
            placeholder="Smith"
            required
            name="ownerLastName"
            value={ownerLastName}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="sign-up-form-field">
        <div>
          <label>Email*</label>
          <input
            type="email"
            placeholder="you@gmail.com"
            required
            name="ownerEmail"
            value={ownerEmail}
            onChange={handleInputChange}
          />
        </div>
        <div className="password-input">
          <label>Password*</label>
          <input
            type={showPassword ? "text" : "password"}
            placeholder="*********"
            required
            name="ownerPassword"
            value={ownerPassword}
            onChange={handleInputChange}
          />
          <div onClick={() => setShowPassword(!showPassword)} className="show-password">
            {!showPassword && <div className="cross-line"></div>}
            <img src={show} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
