import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Confirm = () => {
  const navigate = useNavigate();

  const confirmEmail = async (e) => {
    e.preventDefault();
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isAndroid = /android/i.test(userAgent);

    if (isAndroid) {
      window.location.href =
        "intent://#Intent;action=com.google.android.gm;end";
    } else {
      window.location.href = "https://mail.google.com/";
    }
  };

  return (
    <div className="container auth confirm">
      <div className="">
        <div className="auth-header">
          <h1>Check your email</h1>
          <p>
            Complete verification by following the instruction sent to your
            email
          </p>
        </div>
        <form onSubmit={confirmEmail}>
          <div className="auth-inputs">
            <button type="submit" className="--btn --btn-primary --btn-block">
              Open your mail app
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Confirm;
