import { createSlice } from "@reduxjs/toolkit";

let businessName = localStorage.getItem("businessName") || null;
let user = localStorage.getItem("user") || null;
let businessInfo = localStorage.getItem("BusinessInfo") || null;
let businessAddress = localStorage.getItem("businessAddress") || null

const initialState = {
  isLoggedIn: false,
  businessName: businessName !== "undefined" ? JSON.parse(businessName) : "",
  businessOwnerLoggedIn: localStorage.getItem("admin") !== "undefined"
    ? JSON.parse(localStorage.getItem("admin"))
    : false,
  user: user !== "undefined" ? JSON.parse(user) : {
    name: "",
    email: "",
  },
  business: businessInfo !== "undefined" ? JSON.parse(businessInfo) : {
    businessName: "",
    businessEmail: "",
    businessAddress: businessAddress !== "undefined" ? businessAddress : "",
    photo: "https://i.ibb.co/4pDNDk1/avatar.png",
    ownerFirstName: "",
    ownerLastName: "",
    ownerEmail: "",
    sales: []
  }
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    SET_LOGIN(state, action) {
      state.isLoggedIn = action.payload;
    },
    SET_NAME(state, action) {
      localStorage.setItem("businessName", JSON.stringify(action.payload));
      state.businessName = action.payload;
    },
    SET_USER(state, action) {
      const profile = action.payload;
      localStorage.setItem("admin", JSON.stringify(profile.businessOwnerLoggedIn));
      localStorage.setItem("user", JSON.stringify(profile));
      state.user = profile;
      state.businessOwnerLoggedIn = profile.businessOwnerLoggedIn;
    },
    SET_BUSINESS(state, action) {
      const business = action.payload
      localStorage.setItem("BusinessInfo", JSON.stringify(action.payload));
      localStorage.setItem("businessAddress", JSON.stringify(action.payload?.businessAddress))
      state.business = action.payload;
      // console.log("state.business", state.business);
    }
  },
});

export const { SET_LOGIN, SET_NAME, SET_USER, SET_BUSINESS } = authSlice.actions;

export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectName = (state) => state.auth.businessName;
export const selectUser = (state) => state.auth.user;
export const selectLoggedInBusinessOwner = (state) => state.auth.businessOwnerLoggedIn;
export const selectBusiness =  (state) => state.auth.business
export const selectBusinessAddress =  (state) => state.auth.business?.businessAddress

export default authSlice.reducer; 
