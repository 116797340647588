import React from "react";
import Header from "../Home/homeComponents/Header";
import Footer from "../Home/homeComponents/Footer";
import { CiSearch } from "react-icons/ci";
import blog1 from "../../assets/home/blog1.svg";
import "./blog.css";

export default function Blogs() {
  return (
    <> 
      <Header />
      <div className="blog-section">
        <div className="search-section">
          <div className="search">
            <input type="text" placeholder="Search" />
            <button>
              <CiSearch size="20" />
            </button>
          </div>
        </div>
        <div className="container blog-section-head">
            <h3>Dive into the world of technology with our blog</h3>
            <p>Unveiling the Future: Insights, Innovations, and Tech Trends</p>
        </div>
        <div className="blog-section-body">
            <div className="blog-cards">
                <div className="blog-card">
                    <div className="blog-card-img">
                        <img src={blog1} alt="blog1" />
                    </div>
                    <div className="blog-card-body">
                        <h3>How to choose the right software development company?</h3>
                        <p>Choosing the right software development company is a crucial decision for your business. Here are some tips to help you make the right choice.</p>
                        <button>Read More</button>
                    </div>
                </div>
                <div className="blog-card">
                    <div className="blog-card-img">
                        <img src={blog1} alt="blog1" />
                    </div>
                    <div className="blog-card-body">
                        <h3>How to choose the right software development company?</h3>
                        <p>Choosing the right software development company is a crucial decision for your business. Here are some tips to help you make the right choice.</p>
                        <button>Read More</button>
                    </div>
                </div>
                <div className="blog-card">
                    <div className="blog-card-img">
                        <img src={blog1} alt="blog1" />
                    </div>
                    <div className="blog-card-body">
                        <h3>How to choose the right software development company?</h3>
                        <p>Choosing the right software development company is a crucial decision for your business. Here are some tips to help you make the right choice.</p>
                        <button>Read More</button>
                    </div>
                </div>
                <div className="blog-card">
                    <div className="blog-card-img">
                        <img src={blog1} alt="blog1" />
                    </div>
                    <div className="blog-card-body">
                        <h3>How to choose the right software development company?</h3>
                        <p>Choosing the right software development company is a crucial decision for your business. Here are some tips to help you make the right choice.</p>
                        <button>Read More</button>
                    </div>
                </div>
                <div className="blog-card">
                    <div className="blog-card-img">
                        <img src={blog1} alt="blog1" />
                    </div>
                    <div className="blog-card-body">
                        <h3>How to choose the right software development company?</h3>
                        <p>Choosing the right software development company is a crucial decision for your business. Here are some tips to help you make the right choice.</p>
                        <button>Read More</button>
                    </div>
                </div>
                <div className="blog-card">
                    <div className="blog-card-img">
                        <img src={blog1} alt="blog1" />
                    </div>
                    <div className="blog-card-body">
                        <h3>How to choose the right software development company?</h3>
                        <p>Choosing the right software development company is a crucial decision for your business. Here are some tips to help you make the right choice.</p>
                        <button>Read More</button>
                    </div>
                </div>
                <div className="blog-card">
                    <div className="blog-card-img">
                        <img src={blog1} alt="blog1" />
                    </div>
                    <div className="blog-card-body">
                        <h3>How to choose the right software development company?</h3>
                        <p>Choosing the right software development company is a crucial decision for your business. Here are some tips to help you make the right choice.</p>
                        <button>Read More</button>
                    </div>
                </div>
                <div className="blog-card">
                    <div className="blog-card-img">
                        <img src={blog1} alt="blog1" />
                    </div>
                    <div className="blog-card-body">
                        <h3>How to choose the right software development company?</h3>
                        <p>Choosing the right software development company is a crucial decision for your business. Here are some tips to help you make the right choice.</p>
                        <button>Read More</button>
                    </div>
                </div>
                <div className="blog-card">
                    <div className="blog-card-img">
                        <img src={blog1} alt="blog1" />
                    </div>
                    <div className="blog-card-body">
                        <h3>How to choose the right software development company?</h3>
                        <p>Choosing the right software development company is a crucial decision for your business. Here are some tips to help you make the right choice.</p>
                        <button>Read More</button>
                    </div>
                </div>
                <div className="blog-card">
                    <div className="blog-card-img">
                        <img src={blog1} alt="blog1" />
                    </div>
                    <div className="blog-card-body">
                        <h3>How to choose the right software development company?</h3>
                        <p>Choosing the right software development company is a crucial decision for your business. Here are some tips to help you make the right choice.</p>
                        <button>Read More</button>
                    </div>
                </div>
                <div className="blog-card">
                    <div className="blog-card-img">
                        <img src={blog1} alt="blog1" />
                    </div>
                    <div className="blog-card-body">
                        <h3>How to choose the right software development company?</h3>
                        <p>Choosing the right software development company is a crucial decision for your business. Here are some tips to help you make the right choice.</p>
                        <button>Read More</button>
                    </div>
                </div>
                
            </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
