import React, { useEffect, useState } from "react";
import { SpinnerImg } from "../../loader/Loader";
import "../productList/productList.scss";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai";
import { FaDownload } from "react-icons/fa";
import Search from "../../search/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  FILTER_SALES,
  selectFilteredSales,
} from "../../../redux/features/product/filterSlice";
import {
  CALC_PROFIT_VALUE,
  CALC_SOLD_VALUE,
  selectTotalProfit, 
  selectTotalSales
} from "../../../redux/features/product/productSlice";
import ReactPaginate from "react-paginate";
import "react-confirm-alert/src/react-confirm-alert.css";
import { getSales } from "../../../redux/features/product/productSlice";
import moment from "moment";
import { Link } from "react-router-dom";
import "./salesList.css";

const SalesList = ({ sales, isLoading, admin, checkouts }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const filteredSales = useSelector(selectFilteredSales);
  const totalSales = useSelector(selectTotalSales);
  const totalProfit = useSelector(selectTotalProfit);

 console.log("checkouts", checkouts);

  useEffect(() => {
    dispatch(CALC_SOLD_VALUE(sales));
    dispatch(CALC_PROFIT_VALUE(sales));
  }, [dispatch, sales])

  // const sales = useSelector((state) => state.sales)

  const shortenText = (text, n) => {
    if (text.length > n) {
      const shortenedText = text.substring(0, n).concat("...");
      return shortenedText;
    }
    return text;
  };

  const handleFetchSales = async (e) => {
    await dispatch(getSales(e.target.value))
  } 

  //   Begin Pagination
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(filteredSales.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredSales.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, filteredSales]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredSales.length;
    setItemOffset(newOffset);
  };
  //   End Pagination

  useEffect(() => {
    dispatch(FILTER_SALES({ sales, search }));
  }, [sales, search, dispatch]);

  const formatter=(amount) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(amount);
  } 

  return (
    <div className="product-list">
      <hr />
      <div className="table">
        <div className="--flex-between --flex-dir-column">
          <span>
            <h3>
              Sales Record <Link to={`/dashboard`}>Go to store</Link>
            </h3>
          </span>
          <span>
            <Search
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </span>
        </div>

        <div className="statistics">
          <div className="query">
            <select onChange={handleFetchSales} name="query-sales-dates">
              <option value="1d">LAST 24 HOURS</option>
              <option value="3d">LAST 3 DAYS</option>
              <option value="7d" selected>LAST 7 DAYS</option>
              <option value="14d">LAST 14 DAYS</option>
              <option value="1m">LAST 1 MONTH</option>
              <option value="2m">LAST 2 MONTHS</option>
              <option value="3m">LAST 3 MONTHS</option>
              <option value="6m">LAST 6 MONTHS</option>
              <option value="1y">LAST 1 YEAR</option>
            </select>
          </div>
          <div className="t-sales">
            <span>Total Sales</span> <h1>{admin && formatter(totalSales)}</h1>{" "}
          </div>
          <div className="t-profit">
            <span>Total Profit</span> <h1>{admin && formatter(totalProfit)}</h1>{" "}
          </div>
        </div> 

        {isLoading && <SpinnerImg />}

        <div className="table">
          {!isLoading && sales.length === 0 ? (
            <p>-- All sales will appear here...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>s/n</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Cost Price</th>
                  <th>Selling Price</th>
                  <th>Quantity</th>
                  <th>Sales Value</th>
                  <th>Profit</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((sale, index) => {
                  const {
                    _id,
                    cost,
                    quantity,
                    name,
                    category,
                    createdAt,
                    price,
                  } = sale;
                  const format = "dddd, Do of MMMM, YYYY";

                  // Use moment.js to format the date
                  const formattedDate = moment(createdAt).format(format);
                  return (
                    <tr key={_id}>
                      <td>{index + 1}</td>
                      <td>{formattedDate}</td>
                      <td>{shortenText(name, 16)}</td>
                      <td>
                        {formatter(cost)}
                      </td>
                      <td>
                        {formatter(price)}
                      </td>
                      <td>{quantity}</td>
                      <td>
                        {formatter(price * quantity)}
                      </td>
                      <td>
                        {formatter(price * quantity - cost * quantity)}
                      </td>
                      <td className="icons">
                        <span>
                          <Link to={`/getsale/${_id}`}>
                            <AiOutlineEye size={25} color={"purple"} />
                          </Link>
                        </span>
                        <span>
                          <Link className="receipt" to={`/getsale/${_id}`}>
                            <FaDownload size={18} color={"purple"} />
                            {/* <a>Receipt</a> */}
                          </Link>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="Prev"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="activePage"
        />
      </div>
    </div>
  );
};

export default SalesList;
