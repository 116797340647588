import React, { useState } from "react";
import Header from "../Home/homeComponents/Header";
import Footer from "../Home/homeComponents/Footer";
import "./pricing.css";

export default function Pricing() {
  const [activeClass, setActiveClass] = useState("monthly");
  const [activeFeature, setActiveFeature] = useState("standard");

  const handleClick = (value) => {
    setActiveClass(value);
  };

  const changeActiveFeature = (value) => {
    setActiveFeature(value);
  };
 
  return (
    <>
      <Header />
      <section className="pricing">
        <div className="container pricing-header">
          <h1>We have the plan that is perfect for you</h1>
          <p>
            You're not just subscribing to a tool; you're embracing a
            transformative solution tailored to elevate your operations.
          </p>
        </div>
        <div className="pricing-body">
          <div className="pricing-card-1">
            <div className="subscription-interval">
              <div className="subscription-interval__tabs">
                <div className="subscription-interval__tabs__tab">
                  <button
                    className={
                      activeClass === "monthly" ? "active-interval" : ""
                    }
                    name="monthly"
                    onClick={() => handleClick("monthly")}
                  >
                    Monthly
                  </button>
                </div>
                <div className="subscription-interval__tabs__tab">
                  <button
                    className={
                      activeClass === "yearly" ? "active-interval" : ""
                    }
                    name="yearly"
                    onClick={() => handleClick("yearly")}
                  >
                    Annually
                  </button>
                </div>
              </div>
            </div>
            <p>All plans include a 14-day fully featured free trial.</p>
          </div>
          <div className="features">
            <table>
              <tr className="features-header">
                <th className="features-text">Features</th>
                <th>
                  <div>
                    <h3>Basic</h3>
                    <button
                      onClick={() => changeActiveFeature("basic")}
                      className={
                        activeFeature === "basic" ? "active-feature" : ""
                      }
                    >
                      Get started
                    </button>
                  </div>
                </th>
                <th>
                  <div>
                    <h3>Standard</h3>
                    <button
                      onClick={() => changeActiveFeature("standard")}
                      className={
                        activeFeature === "standard" ? "active-feature" : ""
                      }
                    >
                      Get started
                    </button>
                  </div>
                </th>
                <th>
                  <div>
                    <h3>Professional</h3>
                    <button
                      onClick={() => changeActiveFeature("pro")}
                      className={
                        activeFeature === "pro" ? "active-feature" : ""
                      }
                    >
                      Get started
                    </button>
                  </div>
                </th>
              </tr>
              <tr>
                <td>Record payment installments?</td>
                <td>Yes</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>No. of Products</td>
                <td>Unlimited</td>
                <td>Unlimited</td>
                <td>Unlimited</td>
              </tr>
              <tr>
                <td>Print receipts</td>
                <td>No</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Messages to customers</td>
                <td>No</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Group products</td>
                <td>No</td>
                <td>No</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Salespersons</td>
                <td>3</td>
                <td>5</td>
                <td>Unlimited</td>
              </tr>
              <tr>
                <td>Price</td>
                <td>NGN 5,000.00</td>
                <td>NGN 12,000.00</td>
                <td>NGN 20,000.00</td>
              </tr>
            </table>
          </div>

          <div className="pricing-card-2">
            <h3>Not convinced yet?</h3>
            <button className="btn btn-secondary">Get a free trial</button>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
