import React from "react";
import Header from "../Home/homeComponents/Header";
import Footer from "../Home/homeComponents/Footer";
import { CiSearch } from "react-icons/ci";
import blog2 from "../../assets/home/blog2.svg";
import "./blog.css";

export default function Blog() {
  return (
    <>
      <Header />
      <div className="blog-section single-blog">
        <div className="search-section">
          <div className="search">
            <input type="text" placeholder="Search" />
            <button>
              <CiSearch size="20" />
            </button>
          </div>
        </div>
        <div className="blog-section-body">
          <div className="blog-cards">
            <div className="blog-card">
              <div className="blog-card-img">
                <img src={blog2} alt="blog1" />
              </div>
              <div className="blog-card-body">
                <p>
                  In the vast landscape of technology, where every line of code
                  is a brushstroke on the canvas of digital creation, coding
                  emerges not only as a technical skill but as a profound art
                  form. This journey through the realms of code invites us to
                  explore the nuances of this craft—from insights that lay the
                  foundation to innovations that shape the future.
                </p>
                <h3>How to choose the right software development company?</h3>
                <p>
                  Traditionally, the term "art" evokes images of paintings,
                  sculptures, and creative expressions. However, in the
                  contemporary digital age, coding stands tall as a creative
                  endeavor. Like a painter selects colors and strokes to create
                  a masterpiece, a coder crafts lines of logic and syntax to
                  bring functionality and innovation to life. The elegant dance
                  of algorithms and the structure of well-designed code reflect
                  the artist's touch in the digital realm.
                </p>
                <h3>Insights: The Foundation of Masterful Coding</h3>
                <p>
                  The journey begins with insights—the deep understanding of the
                  problem domain and the ability to envision solutions. Coding
                  is not just about writing lines of code but about
                  comprehending the intricate puzzle presented by a problem.
                  Insights guide us to choose the right tools, design efficient
                  algorithms, and create solutions that stand the test of time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
