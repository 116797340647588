import React, { useEffect, useState } from "react";
import "./groupItem.css";
import editIcon from "../../../assets/home/edit-icon.svg";
import deleteIcon from "../../../assets/home/delete-icon.svg";
import editIcon2 from "../../../assets/home/pencil-2.svg";
import xcrossIcon from "../../../assets/home/xcrossIcon.svg";
import arrowLeft from "../../../assets/home/arrow-left.svg";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProductGroups,
  deleteGroupItem,
} from "../../../redux/features/product/productSlice";
import {
  selectIsLoggedIn,
  selectUser,
} from "../../../redux/features/auth/authSlice";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { Tooltip } from "antd";
import moment from "moment";

export default function GroupItem({ admin }) {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { allProductGroups } = useSelector((state) => state.allProductGroups);
  const [filteredGroup, setFilteredGroup] = useState([]);
  const [attributesArrays, setAttributesArrays] = useState([]);
  const [activeRoute, setActiveRoute] = useState("");
  const [showProductItems, setShowProductItems] = useState(true);
  const [products, setProducts] = useState([]);
  const currentUser = useSelector(selectUser);
  const [showProductImage, setShowProductImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const { id } = useParams();

  const linkArrays = (keys, ...arrays) => {
    const length = arrays[0].length;
    if (!arrays.every((arr) => arr.length === length)) {
      toast.error("Arrays must be of equal length");
    }
    return Array.from({ length }, (_, index) => {
      return arrays.reduce((obj, arr, i) => {
        obj[keys[i]] = arr[index];
        return obj;
      }, {});
    });
  };

  const linkArrayAndObject = (array, object) => {
    const length = array.length;

    if (Object.keys(object).length !== length) {
      throw new Error("Array and object must have the same length");
    }

    return array.map((key, index) => ({
      [key]: { ...object[index] },
    }));
  };

  useEffect(() => {
    const filtered = allProductGroups.filter((prg) => prg._id === id);
    setFilteredGroup(filtered);
    if (filtered.length > 0) {
      setAttributesArrays(
        linkArrayAndObject(filtered[0].attributes, filtered[0].options)
      );
      setProducts(
        linkArrays(
          ["itemName", "sku", "cost", "price", "warehouse"],
          filtered[0].combinations,
          filtered[0].sku,
          filtered[0].cost,
          filtered[0].price,
          filtered[0].warehouse
        )
      );
    }
  }, [allProductGroups, id]);

  useEffect(() => {
    if (isLoggedIn === true) {
      dispatch(getAllProductGroups());
    }
  }, [isLoggedIn, dispatch]);

  const formatter = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    }).format(amount);
  };

  const shortenText = (text, n) => {
    if (text.length > n) {
      const shortenedText = text.substring(0, n).concat("...");
      return shortenedText;
    }
    return text;
  };

  const delProduct = async (id) => {
    await dispatch(deleteGroupItem(id));
    await dispatch(getAllProductGroups());
  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Delete Product",
      message: "Are you sure you want to delete this product.",
      buttons: [
        {
          label: "Delete",
          onClick: () => delProduct(id),
        },
        {
          label: "Cancel",
          // onClick: () => alert('Click No')
        },
      ],
    });
  };

  const handleShowProductImage = (imagePath) => {
    setSelectedImage(imagePath);
    setShowProductImage(true);
  };

  const closeProductImage = () => {
    setShowProductImage(false);
    setSelectedImage(null);
  }; 

  return (
    <div className="group-items-container">
      <div className="items-list">
        <h1>Item groups</h1>
        <div className="group-items">
          {allProductGroups.map((productGroup, index) => {
            return (
              <Link to={`/inventory/product-group/${productGroup._id}`}>
                <div
                  key={index}
                  className={
                    productGroup._id === id
                      ? "group-item active-group-item"
                      : "group-item"
                  }
                  onClick={() => setShowProductItems(!showProductItems)}
                >
                  <div className="item-dot"></div>
                  <h3>
                    {productGroup.groupName}{" "}
                    <span>({productGroup.combinations.length} items)</span>
                  </h3>
                </div>
              </Link>
            );
          })}
        </div>
      </div>

      <div
        className={
          showProductItems ? "item-details show_details" : "item-details"
        }
      >
        <div className="">
          <div className="details-header">
            <div className="actions">
              {admin || currentUser?.permissions?.editproducts ? (
                <Link to={`/edit-product/group/${id}`}>
                  <img src={editIcon2} alt="edit" />
                </Link>
              ) : null}
              {admin || currentUser?.permissions?.editproducts ? (
                <Link to={`/edit-product/group/${id}`}>
                  <button className="btn_edit_add_item">Add Item</button>
                </Link>
              ) : null}
            </div>
            <div className="">
              <img
                onClick={() => setShowProductItems(!showProductItems)}
                src={showProductItems ? arrowLeft : xcrossIcon}
                alt="edit"
              />
            </div>
          </div>
        </div>

        <div className="details-body">
          {filteredGroup?.map((filtered, index) => {
            // log
            const format = "DD-MM-YYYY h:mmA";
            const createdAt = moment(filtered.createdAt).format(format);
            const updatedAt = moment(filtered.updatedAt).format(format);
            return (
              <div key={index}>
                <div className="details_body_header">
                  <h3>{filtered.groupName}</h3>
                  <h5>{filtered.combinations.length} item(s)</h5>
                  <div className="dates">
                    <h6>Created: {createdAt}</h6>
                    <h6>Updated: {updatedAt}</h6>
                  </div>
                </div>

                {filtered?.image && (
                  <div className="product_group_image">
                    <img
                      onClick={() =>
                        handleShowProductImage(filtered?.image?.filePath)
                      }
                      src={filtered?.image?.filePath}
                      alt=""
                    />
                    {showProductImage && (
                      <div className="show_product_image">
                        <div className="close_btn" onClick={closeProductImage}>
                          <img src={xcrossIcon} alt="close" />
                        </div>
                        <div className="product_image_preview">
                          <img src={selectedImage} alt="enlarged product" />
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <table>
                  {attributesArrays.map((attributesArray, index) => {
                    const [key, value] = Object.entries(attributesArray)[0];

                    return (
                      <tr key={index}>
                        <td>{key}</td>
                        <td>
                          {value.attr.map((val, i) => {
                            return val.value !== "" ? (
                              <span key={i}>{val.value}</span>
                            ) : null;
                          })}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            );
          })}
        </div>

        <div className="details-body-items">
          <table>
            <thead>
              <tr>
                <th>Item Name</th>
                <th>SKU</th>
                <th>Cost Price</th>
                <th>Selling Price</th>
                <th>Warehouse</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => {
                return (
                  <tr>
                    <td>
                      {/* <Tooltip title={product.itemName}> */}
                      {/* {shortenText(product.itemName, 16)} */}
                      {/* </Tooltip> */}
                      {product.itemName}
                    </td>
                    <td>
                      <Tooltip title={product.sku}>
                        {shortenText(product.sku, 16)}
                      </Tooltip>
                    </td>
                    <td>{formatter(product.cost)}</td>
                    <td>{formatter(product.price)}</td>
                    <td>{product.warehouse}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="delete_product_group_button">
          {admin || currentUser?.permissions?.deleteproducts ? (
            <button onClick={() => confirmDelete(id)} className="btn_delete">
              <img src={deleteIcon} alt="" />
              Delete Item Group
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
