import { useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import plus from "../../../assets/home/plus-circle.svg";
import minus from "../../../assets/home/minus-circle.svg";
import "../custom.scss";

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <>
      <div className="text-icon" onClick={decoratedOnClick}>
        <p>{children}</p>
        <img src={isCurrentEventKey ? minus : plus} alt="faq" />
      </div>
    </>
  );
}

export default function GeneralFaq() {
  return (
    <Accordion className="accordion">
      <Card className="custom-bootstrap-faq-header">
        <Card.Header className="custome-card-header">
          <ContextAwareToggle eventKey="0">
            What is this inventory software used for?
          </ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="faq-answers">
            Our inventory software is designed to assist businesses of all sizes
            in efficiently tracking and managing their inventory. With our
            software, you can easily determine the value of your stock at any
            given time, monitor profits, and understand the various financial
            channels through which money flows in and out of your business. This
            solution eliminates the need for manual paperwork, streamlining your
            operations and enhancing accuracy.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className="custom-bootstrap-faq-header">
        <Card.Header className="custome-card-header">
          <ContextAwareToggle eventKey="1">
            Is this software suitable for small businesses?
          </ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body className="faq-answers">
            Yes, our software is scalable and can be customized to meet the
            needs of small businesses as well as larger enterprises.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className="custom-bootstrap-faq-header">
        <Card.Header className="custome-card-header">
          <ContextAwareToggle eventKey="2">
            Can I use this software for multiple locations?
          </ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body className="faq-answers">
            Yes, our software supports multi-location inventory management,
            allowing you to track inventory across various warehouses and store
            locations.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}
