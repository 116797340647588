import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { BACKEND_URL } from "../../services/authService";
import Header from "../Home/homeComponents/Header";
import Footer from "../Home/homeComponents/Footer";
import envelop from "../../assets/home/envelop.svg";
import phone from "../../assets/home/phone.svg";
import home from "../../assets/home/home.svg";
import "./contact.css";

const Contact = () => {
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const data = {
    subject,
    message,
    email
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BACKEND_URL}/api/contactus`, data);
      setSubject("");
      setMessage("");
      setEmail("");
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <Header /> 
      <div className="contact-us-page">
        <div className="contact-us-header">
          <h1>Get in touch with us</h1>
          <p>
            Reach out to us for personalized solutions, expert guidance, or any
            inquiries you may have.
          </p>
        </div>
        <div className="conatct-us-cards">
          <div className="contact-us-card">
            <img src={envelop} alt="contact envelop" />
            <h3>yemijoshua80@gmail.com</h3>
          </div>
          <div className="contact-us-card">
            <img src={phone} alt="contact phone" />
            <a href="tel:+2348065109764"><h3>(234) 806-510-9764</h3></a>
          </div>
          <div className="contact-us-card">
            <img src={home} alt="contact home" />
            <h3>Shop SS9 Futa Shopping Complex, beside GTB</h3>
          </div>
        </div>
        <div className="contact-form">
          <div className="contact-form-text">
            <h3>Send a message</h3>
            <p>
              Ready to transform your inventory management or have questions
              about our services? Our team is here to assist you. Let's connect
              and explore the possibilities together.
            </p>
          </div>
          <div className="contact-form-inputs">
            <form onSubmit={sendEmail}>
              <div className="input-group">
                <div className="input-field">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-field">
                  <label>Subject</label>
                  <input
                    type="text"
                    name="subject"
                    required
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </div>

              </div>
              <div className="input-group">
                <div className="input-field message">
                  <label>Message</label>
                  <textarea
                    cols="30"
                    rows="10"
                    name="message"
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <button className="--btn --btn-primary">Send Message</button>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
