import React, { useEffect, useState } from "react";
import { SpinnerImg } from "../../loader/Loader";
import "../productList/productList.scss";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import {
  FILTER_OUT_OF_STOCK_PRODUCT_GROUPS,
  FILTER_OUT_OF_STOCK_PRODUCTS,
  selectFilteredProductGroupOutOfStock,
  selectFilteredProductsOutOfStock,
} from "../../../redux/features/product/filterSlice";
import {
  deleteProduct,
  getOutOfStock,
} from "../../../redux/features/product/productSlice";
import ReactPaginate from "react-paginate";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import deleteIcon from "../../../assets/home/delete-icon.svg";
import editIcon from "../../../assets/home/edit-icon.svg";
import InventoryHeader from "../../inventoryHeader/InventoryHeader";

const OutOfStockList = ({
  productsOutOfStock,
  productGroupOutOfStock,
  isLoading,
  admin,
}) => {
  const [search, setSearch] = useState("");
  const [showProducts, setShowProducts] = useState(true);
  const [showProductGroups, setShowProductGroups] = useState(true);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  const filteredOutOfStockProducts = useSelector(
    selectFilteredProductsOutOfStock
  );
  const filteredOutOfStockProductGroups = useSelector(
    selectFilteredProductGroupOutOfStock
  );

  const dispatch = useDispatch();

  const shortenText = (text, n) =>
    text.length > n ? text.substring(0, n).concat("...") : text;

  useEffect(() => {
    const currentData = showProducts
      ? filteredOutOfStockProducts
      : filteredOutOfStockProductGroups;
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(currentData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(currentData.length / itemsPerPage));
  }, [
    itemOffset,
    itemsPerPage,
    showProducts,
    filteredOutOfStockProducts,
    filteredOutOfStockProductGroups,
  ]);

  useEffect(() => {
    dispatch(FILTER_OUT_OF_STOCK_PRODUCTS({ productsOutOfStock, search }));
    dispatch(
      FILTER_OUT_OF_STOCK_PRODUCT_GROUPS({ productGroupOutOfStock, search })
    );
  }, [productsOutOfStock, productGroupOutOfStock, search, dispatch]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) %
      (showProducts
        ? filteredOutOfStockProducts.length
        : filteredOutOfStockProductGroups.length);
    setItemOffset(newOffset);
  };

  const delProduct = async (id) => {
    await dispatch(deleteProduct(id));
    await dispatch(getOutOfStock());
  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Delete Product",
      message: "Are you sure you want to delete this product?",
      buttons: [
        { label: "Delete", onClick: () => delProduct(id) },
        { label: "Cancel" },
      ],
    });
  };

  return (
    <div className="product-list">
      <div className="table">
        <InventoryHeader
          search={search}
          handleSearchChange={(e) => setSearch(e.target.value)}
        />
        {isLoading && <SpinnerImg />}

        <h2
          onClick={() => setShowProducts(!showProducts)}
          style={{ cursor: "pointer" }}
        >
          {showProducts ? "▼" : "►"} Out of Stock Products
        </h2>
        {showProducts && (
          <>
            {productsOutOfStock.length === 0 ? (
              <p className="no-products-p">-- Out of stock list --</p>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Name</th>
                    <th>Category</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredOutOfStockProducts
                    .slice(itemOffset, itemOffset + itemsPerPage)
                    .map((productOutOfStock, index) => {
                      const { _id, name, category } = productOutOfStock;
                      return (
                        <tr key={_id}>
                          <td>{index + 1}</td>
                          <td>{shortenText(name, 16)}</td>
                          <td>{category}</td>
                          <td className="icons">
                            {admin && (
                              <Link to={`/edit-product/${_id}`}>
                                <span>
                                  <img src={editIcon} alt="edit" />
                                </span>
                              </Link>
                            )}
                            {admin && (
                              <span>
                                <img
                                  onClick={() => confirmDelete(_id)}
                                  src={deleteIcon}
                                  alt="delete"
                                />
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </>
        )}

        {/* Out of Stock Product Groups Section */}
        <h2
          onClick={() => setShowProductGroups(!showProductGroups)}
          style={{ cursor: "pointer" }}
        >
          {showProductGroups ? "▼" : "►"} Out of Stock Product Groups
        </h2>
        {showProductGroups && (
          <>
            {productGroupOutOfStock.length === 0 ? (
              <p className="no-products-p">
                -- Out of stock product groups list --
              </p>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Group Name</th>
                    <th>Category</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredOutOfStockProductGroups
                    .slice(itemOffset, itemOffset + itemsPerPage)
                    .map((productGroup, index) => {
                      const { _id, groupName, category } = productGroup;
                      return (
                        <tr key={_id}>
                          <td>{index + 1}</td>
                          <td>{shortenText(groupName, 16)}</td>
                          <td>{category}</td>
                          <td className="icons">
                            {admin && (
                              <Link to={`/edit-product/group/${_id}`}>
                                <span>
                                  <img src={editIcon} alt="edit" />
                                </span>
                              </Link>
                            )}
                            {admin && (
                              <span>
                                <img
                                  onClick={() => confirmDelete(_id)}
                                  src={deleteIcon}
                                  alt="delete"
                                />
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </>
        )}

        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="<"
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="activePageClass"
        />
      </div>
    </div>
  );
};

export default OutOfStockList;
