import React, { useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { NavLink } from "react-router-dom";

const activeLink = ({ isActive }) => (isActive ? "active-link" : "link");

const SidebarItem = ({ item, isOpen, count, handleShowMenu, count2 }) => {
  return (
    <NavLink to={item.path} className={activeLink}>
      <div className="sidebar-item s-parent">
        <div className="sidebar-title">
          {item.count ? (
            <div>
              {item.countType === "cart" ? (
                <span onClick={() => handleShowMenu()}> 
                  {item.icon && <div className="icon">{item.icon}</div>}
                  <div className="title">{item.title}</div>
                  <div className="cart-count">{count}</div>
                </span>
              ) : (
                <span onClick={() => handleShowMenu()}>
                  {item.icon && <div className="icon">{item.icon}</div>}
                  <div className="title">{item.title}</div>
                  <div className="cart-count">{count2}</div>
                </span>
              )}
            </div>
          ) : (
            <div>
              <span onClick={() => handleShowMenu()}>
                {item.icon && <div className="icon">{item.icon}</div>}
                <div className="title">{item.title}</div>
              </span>
            </div>
          )}
        </div>
      </div>
    </NavLink>
  );
};

export default SidebarItem;
