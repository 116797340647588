import { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import Home from "./pages/Home/Home";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Forgot from "./pages/auth/Forgot";
import Reset from "./pages/auth/Reset";
import Dashboard from "./pages/dashboard/Dashboard";
import Layout from "./components/layout/Layout";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { getLoginStatus } from "./services/authService";
import {
  SET_LOGIN,
  selectLoggedInBusinessOwner,
} from "./redux/features/auth/authSlice";
import AddProduct from "./pages/addProduct/AddProduct";
import EditProduct from "./pages/editProduct/EditProduct";
import Contact from "./pages/contact/Contact";
import Cart from "./pages/cart/Cart";
import Pricing from "./pages/pricing/Pricing";
import Blogs from "./pages/blog/Blogs";
import Blog from "./pages/blog/Blog";
import About from "./pages/about/About";
import Confirm from "./pages/auth/Confirm";
import SuccessRegistered from "./pages/auth/registerComponents/SuccessRegistered";
import Inventory from "./pages/inventory/Inventory";
import Customers from "./pages/customers/Customers";
import Account from "./pages/accounts/Account";
import AddProductGroup from "./pages/addProduct/AddProductGroup";
import GroupItem from "./components/product/productGroupList/GroupItem";
import Fulfilment from "./pages/fulfilment/Fulfilment";
import Policy from "./pages/policy/Policy";
import Terms from "./pages/terms/Terms";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import Admin from "./pages/admin/Admin";
import EditProductGroup from "./pages/editProduct/EditProductGroup";

axios.defaults.withCredentials = true;

function App() {
  const dispatch = useDispatch();
  const admin = useSelector(selectLoggedInBusinessOwner);

  useEffect(() => {
    async function loginStatus() {
      const status = await getLoginStatus();
      dispatch(SET_LOGIN(status));
    }
    loginStatus();
  }, [dispatch]);

  return (
    <BrowserRouter>
      <ToastContainer />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register/success" element={<SuccessRegistered />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/forgot/confirm" element={<Confirm />} />
        <Route path="/resetpassword/:resetToken" element={<Reset />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/blog/:id" element={<Blog />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/our-policy" element={<Policy />} />
        <Route path="/terms-and-agreement" element={<Terms />} />
        <Route
          path="/admin"
          element={
            <Layout>
              <Admin />
            </Layout>
          }
        />

        <Route
          path="/inventory"
          element={
            <Layout>
              <Inventory />
            </Layout>
          }
        />

        <Route
          path="/inventory/product-group/:id"
          element={
            <Layout>
              <GroupItem admin={admin} />
            </Layout>
          }
        />

        <Route
          path="/inventory/:id"
          element={
            <Layout>
              <Inventory />
            </Layout>
          }
        />

        <Route
          path="/customers"
          element={
            <Layout>
              <Customers />
            </Layout>
          }
        />

        <Route
          path="/accounts"
          element={
            <Layout>
              <Account />
            </Layout>
          }
        />

        <Route
          path="/accounts/:id"
          element={
            <Layout>
              <Account />
            </Layout>
          }
        />

        <Route
          path="/dashboard"
          element={
            <Layout>
              <Dashboard />
            </Layout>
          }
        />

        <Route
          path="/add-product"
          element={
            <Layout>
              <AddProduct />
            </Layout>
          }
        />

        <Route
          path="/add-product-group"
          element={
            <Layout>
              <AddProductGroup />
            </Layout>
          }
        />

        <Route
          path="/edit-product/:id"
          element={
            <Layout>
              <EditProduct />
            </Layout>
          }
        />

        <Route
          path="/edit-product/group/:id"
          element={
            <Layout>
              <EditProductGroup />
            </Layout>
          }
        />

        <Route
          path="/cart"
          element={
            <Layout>
              <Cart />
            </Layout>
          }
        />

        <Route
          path="/fulfilments"
          element={
            <Layout>
              <Fulfilment />
            </Layout>
          }
        />

        <Route
          path="/fulfilments/:id"
          element={
            <Layout>
              <Fulfilment />
            </Layout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
