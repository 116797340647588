import React, { useState } from "react";
import show from "../../../assets/home/show.svg";

export default function Business({
  handleInputChange,
  businessName,
  businessEmail,
  businessAddress,
  businessPhone,
  salesFirstName,
  salesLastName,
  industry,
  country,
  salesEmail,
  salesPassword,
}) {

  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className="sign-up-forms-1">
      <div className="sign-up-form-field">
        <div>
          <label>Business Name*</label>
          <input
            type="text"
            placeholder="chemicals ltd"
            required
            name="businessName"
            value={businessName}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Business Email*</label>
          <input
            type="email"
            placeholder="business@gmail.com"
            required
            name="businessEmail"
            value={businessEmail}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="sign-up-form-field">
        <div>
          <label>Business Address*</label>
          <input
            type="text"
            placeholder="landmark, street ..."
            required
            name="businessAddress"
            value={businessAddress}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Business Phone*</label>
          <input
            type="text"
            placeholder="+2348065109764"
            required
            name="businessPhone"
            value={businessPhone}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="sign-up-form-field">
        <div>
          <label>Industry*</label>
          <input
            type="text"
            placeholder="Technology"
            required
            name="industry"
            value={industry}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Country*</label>
          <input
            type="text"
            placeholder="Nigeria"
            required
            name="country"
            value={country} 
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="sign-up-form-field">
        <div>
          <label>Sales First Name*</label>
          <input
            type="text"
            placeholder="John"
            required
            name="salesFirstName"
            value={salesFirstName}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Sales Last Name*</label>
          <input
            type="text"
            placeholder="Doe"
            required
            name="salesLastName"
            value={salesLastName}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="sign-up-form-field">
        <div>
          <label>Sales Email*</label>
          <input
            type="email"
            placeholder="sales@gmail.com"
            required
            name="salesEmail"
            value={salesEmail}
            onChange={handleInputChange}
          />
        </div>
        <div className="password-input">
          <label>Sales Password*</label>
          <input
            type={showPassword ? "text" : "password"}
            placeholder="*********"
            required
            name="salesPassword"
            value={salesPassword}
            onChange={handleInputChange}
          />
          <div onClick={() => setShowPassword(!showPassword)} className="show-password">
            {!showPassword && <div className="cross-line"></div>}
            <img src={show} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
