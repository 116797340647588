import { useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import plus from "../../../assets/home/plus-circle.svg";
import minus from "../../../assets/home/minus-circle.svg";
import "../custom.scss";

function ContextAwareToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <>
            <div className='text-icon' onClick={decoratedOnClick}>
                <p>{children}</p>
                <img src={isCurrentEventKey ? minus : plus} alt="faq" />
            </div>
        </>
    );
}

export default function FeesFaq() {
    return (
        <Accordion> 
            <Card className='custom-bootstrap-faq-header'>
                <Card.Header className='custome-card-header'>
                    <ContextAwareToggle eventKey="0">
                        What is the cost of the software?
                    </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body className='faq-answers'>Our pricing varies depending on the features and number of users. Please contact our sales team for a customized quote.</Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card className='custom-bootstrap-faq-header'>
                <Card.Header className='custome-card-header'>
                    <ContextAwareToggle eventKey="1">
                        Is there a free trial available?
                    </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body className='faq-answers'>Yes, we offer a 14 days free trial so you can explore the features and benefits of our software before making a commitment.</Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card className='custom-bootstrap-faq-header'>
                <Card.Header className='custome-card-header'>
                    <ContextAwareToggle eventKey="2">
                        What kind of support do you offer?
                    </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body className='faq-answers'>We offer comprehensive support, including video tutorials, email support, and live chat.</Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card className='custom-bootstrap-faq-header'>
                <Card.Header className='custome-card-header'>
                    <ContextAwareToggle eventKey="3">
                        Are software updates included in the subscription?
                    </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body className='faq-answers'>Yes, all software updates are included in your subscription at no additional cost.</Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
}
