import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { loginUser, validateEmail } from "../../services/authService";
import { SET_LOGIN, SET_NAME, SET_USER } from "../../redux/features/auth/authSlice";
import Loader from "../../components/loader/Loader";
import show from "../../assets/home/show.svg";
import "./auth.css";

const initialState = {
  businessEmail: "",
  email: "",
  password: "",
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setformData] = useState(initialState);
  const { businessEmail, email, password } = formData;
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();

 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });
  };

  const login = async (e) => {
    e.preventDefault();

    if (!businessEmail || !email || !password) {
      return toast.error("All fields are required");
    }

    if (!validateEmail(email) || !validateEmail(businessEmail)) {
      return toast.error("Please enter a valid email");
    }

    const userData = {
      businessEmail,
      email,
      password,
    };
    setIsLoading(true);
    try {
      const data = await loginUser(userData);
      await dispatch(SET_LOGIN(true));
      await dispatch(SET_NAME(data.businessName));
      await dispatch(SET_USER(data));
      
      const queryParams = new URLSearchParams(location.search);
      const redirectUrl = queryParams.get("redirect_url");

      if (redirectUrl) {
        navigate(redirectUrl);
      } else {
        navigate("/dashboard");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="container auth">
      {isLoading && <Loader />}
      <div className="">
        <div className="auth-header">
          <h1>Login</h1>
        </div>
        <form onSubmit={login}>
          <div className="auth-inputs">
            <div className="input-field">
              <label>Business Email</label>
              <input
                type="email"
                placeholder="business@gmail.com"
                name="businessEmail"
                required
                value={businessEmail}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-field">
              <label>Personal Email</label>
              <input
                type="email"
                placeholder="you@gmail.com"
                required
                name="email"
                value={email}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-field">
              <div className="password-input">
                <label>Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="*********"
                  required
                  name="password"
                  value={password}
                  onChange={handleInputChange}
                />
                <div onClick={() => setShowPassword(!showPassword)} className="show-password">
                  {!showPassword && <div className="cross-line"></div>}
                  <img src={show} alt="" />
                </div>
              </div>
            </div>
            <Link className="forgot-password" to="/forgot">Forgot Password?</Link>
            <button type="submit" className="--btn --btn-primary --btn-block">
              Login
            </button>
            <p> Don't have an account? <Link className="bold" to="/register">Sign Up</Link></p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
