import Footer from "../Home/homeComponents/Footer";
import Header from "../Home/homeComponents/Header";
import "./policy.css";

const Policy = () => {
  return (
    <div className="policy_page">
      <Header />
      <div className="policy_content">
        <h1>Privacy Policy</h1>
        <p className="update_date">Last Updated: Monday, 5th of August.</p>

        <h2>1. Introduction</h2>
        <p>
          Welcome to GNLIFE Inventory. We are committed to protecting your
          personal information and your right to privacy. If you have any
          questions or concerns about this privacy policy, or our practices
          regarding your personal information, please contact us at: &nbsp;
          <a href="mailto:yemijoshua80@gmail.com">yemijoshua80@gmail.com</a>.
        </p>
        <p>
          This Privacy Policy explains what information we collect, how we use
          it, and what rights you have in relation to it.
        </p>

        <h2>2. Information We Collect</h2>
        <p>
          We collect personal information that you voluntarily provide to us
          when you register on the platform, express an interest in obtaining
          information about us or our products and services, participate in
          activities on the platform, or otherwise contact us.
        </p>
        <p>The personal information we collect may include the following:</p>
        <ul>
          <li>
            <strong>Name and Contact Data:</strong> We collect your first and
            last name, email address, postal address, phone number, and other
            similar contact data.
          </li>
          <li>
            <strong>Credentials:</strong> We collect passwords, and similar
            security information used for authentication and account access.
          </li>
          <li>
            <strong>Payment Data:</strong> We collect data necessary to process
            your payment if you make purchases, such as your payment instrument
            number (excluding your credit/debit card number and other data that may
            authenticate a payment). Your credit/debit card informations may be
            collected by our third parties.
          </li>
        </ul>

        <h2>3. How We Use Your Information</h2>
        <p>We use the information we collect or receive:</p>
        <ul>
          <li>To facilitate account creation and log on process.</li>
          <li>
            To send administrative information to you. This may include
            information about our policies, terms, and conditions.
          </li>
          <li>
            To fulfill and manage your orders, payments, returns, and exchanges
            made through the platform.
          </li>
          <li>
            To deliver targeted advertising and marketing communications to you.
          </li>
          <li>To manage user accounts and provide customer support.</li>
        </ul>

        <h2>4. Sharing Your Information</h2>
        <p>
          We may process or share your data based on the following legal bases:
        </p>
        <ul>
          <li>
            <strong>Consent:</strong> We may process your data if you have given
            us specific consent to use your personal information in a specific
            way.
          </li>
          <li>
            <strong>Legitimate Interests:</strong> We may process your data when
            it is reasonably necessary to achieve our legitimate business
            interests.
          </li>
          <li>
            <strong>Performance of a Contract:</strong> Where we have entered
            into a contract with you, we may process your personal information
            to fulfill the terms of our contract.
          </li>
          <li>
            <strong>Legal Obligations:</strong> We may disclose your information
            where we are legally required to do so in order to comply with
            applicable law, governmental requests, or legal processes.
          </li>
        </ul>

        <h2>5. Data Security</h2>
        <p>
          We have implemented appropriate technical and organizational security
          measures designed to protect the security of any personal information
          we process. However, please also remember that we cannot guarantee
          that the internet itself is 100% secure.
        </p>

        <h2>6. Your Privacy Rights</h2>
        <p>
          Depending on where you are located, you may have the following rights
          with regard to your personal information:
        </p>
        <ul>
          <li>
            The right to request access to the personal data we collect from
            you.
          </li>
          <li>The right to request that we amend or delete your data.</li>
          <li>
            The right to restrict or object to the processing of your personal
            data.
          </li>
        </ul>
        <p>
          To exercise these rights, please contact us at{" "}
          <a href="mailto:yemijoshua80@gmail.com">yemijoshua80@gmail.com</a>.
        </p>

        <h2>7. Cookies and Tracking Technologies</h2>
        <p>
          We may use cookies and similar tracking technologies to access or
          store information. Specific information about how we use such
          technologies and how you can refuse certain cookies is set out in our
          Cookie Policy.
        </p>

        <h2>8. Third-Party Services</h2>
        <p>
          Our platform may include links to third-party websites, plug-ins, and
          applications. Clicking on those links or enabling those connections
          may allow third parties to collect or share data about you. We do not
          control these third-party websites and are not responsible for their
          privacy statements.
        </p>

        <h2>9. Changes to This Policy</h2>
        <p>
          We may update this Privacy Policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal, or regulatory reasons.
        </p>

        <h2>10. Contact Us</h2>
        <p>
          If you have questions or comments about this policy, you may email us
          at <a href="mailto:yemijoshua80@gmail.com">yemijoshua80@gmail.com</a>{" "}
          or by mail to:
        </p>
        <p>
          GNLIFE Inventory
          <br />
          Shop SS9 Futa Shopping Complex, beside GTB
          <br />
          Akure, Ondo State
          <br />
          Nigeria
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default Policy;
