import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductList from "../../components/product/productList/ProductList";
import {
  selectIsLoggedIn,
  selectLoggedInBusinessOwner,
} from "../../redux/features/auth/authSlice";
import {
  getProducts,
  getSales,
  getOutOfStock,
  getAllProductGroups,
} from "../../redux/features/product/productSlice";
import SalesList from "../../components/product/salesList/salesList";
import OutOfStockList from "../../components/product/outOfStockList/outOfStockList";
import NewSalesList from "../../components/product/salesList/NewSalesList";
import { useParams } from "react-router-dom";
import {
  CALC_CART_ITEMS,
  getCheckouts,
} from "../../redux/features/cart/cartSlice";
import ProductGroupList from "../../components/product/productGroupList/ProductGroupList";

const Inventory = () => {
  const dispatch = useDispatch();
  const admin = useSelector(selectLoggedInBusinessOwner);

  const { id } = useParams();

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { products, isLoading, isError, message } = useSelector(
    (state) => state.product
  );

  const { sales } = useSelector((state) => state.sales);
  const { productsOutOfStock } = useSelector(
    (state) => state.productsOutOfStock
  );

  const { productGroupOutOfStock } = useSelector(
    (state) => state.productGroupOutOfStock
  );


  const { checkouts, isCartLoading } = useSelector((state) => state.checkouts);
  const { allProductGroups } = useSelector((state) => state.allProductGroups);

  useEffect(() => {
    if (isLoggedIn === true) {
      dispatch(getProducts());
    }

    if (isLoggedIn === true) {
      dispatch(getSales());
    }

    if (isLoggedIn === true) {
      dispatch(getCheckouts({}));
    }

    if (isLoggedIn === true) {
      dispatch(getOutOfStock());
    }

    if (isLoggedIn === true) {
      dispatch(getAllProductGroups());
    }

    if (isError) {
      console.log(message);
    }
  }, [isLoggedIn, isError, message, dispatch]);

  return (
    <div>
      {id && (
        <div>
          {id === "sales-checkin" ? (
            <SalesList
              sales={sales}
              isLoading={isLoading}
              admin={admin}
              activeRoute={true}
              checkouts={checkouts}
            />
          ) : (
            ""
          )}
        </div>
      )}
      {id && (
        <div>
          {id === "sales-checkin-new" ? (
            <NewSalesList
              sales={sales}
              isLoading={isCartLoading}
              admin={admin}
              checkouts={checkouts}
              activeRoute={true}
            />
          ) : (
            ""
          )}
        </div>
      )}
      {id && (
        <div>
          {id === "out-of-stock" ? (
            <OutOfStockList
              productsOutOfStock={productsOutOfStock}
              productGroupOutOfStock={productGroupOutOfStock}
              isLoading={isLoading}
              admin={admin}
              activeRoute={true}
            />
          ) : (
            ""
          )}
        </div>
      )}
      {id && (
        <div>
          {id === "product-groups" ? (
            <ProductGroupList
              productGroups={allProductGroups}
              isLoading={isLoading}
              admin={admin}
              activeRoute={true}
            />
          ) : (
            ""
          )}
        </div>
      )}
      {!id || id === undefined ? (
        <ProductList
          products={products}
          isLoading={isLoading || isCartLoading}
          admin={admin}
          activeRoute={true}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Inventory;
