import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { updateBusiness, validateEmail } from "../../../services/authService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SET_NAME, selectLoggedInBusinessOwner, selectUser } from "../../../redux/features/auth/authSlice";
import Loader from "../../../components/loader/Loader";
import verify6 from "../../../assets/home/verify6i.svg";
import "../../auth/signup.css";
import show from "../../../assets/home/show.svg";

const EditBusiness = ({ business, handleCancel }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setformData] = useState(business);
  const [selectedImage, setSelectedImage] = useState(null);
  const [profileImage, setProfileImage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const currentUser = useSelector(selectUser);
  const admin = useSelector(selectLoggedInBusinessOwner);

  const fileInputRef = useRef(null);

  const {
    businessName,
    businessEmail,
    ownerFirstName,
    ownerLastName,
    ownerEmail,
    ownerPassword,
    businessAddress,
    businessPhone,
    industry,
    photo,
    country,
  } = formData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    // console.log(file)

    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setProfileImage(e.target.files[0]);
      setformData({ ...formData, image: file });
    }
  };

  const handlePencilClick = () => {
    fileInputRef.current.click();
  };

  const register = async (e) => {
    e.preventDefault();

    if (!validateEmail(businessEmail)) {
      return toast.error("Please enter a valid email");
    }

    setIsLoading(true);
    try {
      let imageURL;
      if (
        profileImage &&
        (profileImage.type === "image/jpeg" ||
          profileImage.type === "image/jpg" ||
          profileImage.type === "image/png")
      ) {
        const image = new FormData();
        image.append("file", profileImage);
        image.append("cloud_name", "dfrwntkjm");
        image.append("upload_preset", "hqq7lql7");

        const response = await fetch(
          "https://api.cloudinary.com/v1_1/dfrwntkjm/image/upload",
          { method: "post", body: image }
        );
        const imgData = await response.json();
        imageURL = imgData.url.toString();
      }

      const userData = {
        businessName,
        businessEmail,
        ownerFirstName,
        ownerLastName,
        ownerEmail,
        businessAddress,
        businessPhone,
        ownerPassword,
        industry,
        country,
        photo: profileImage ? imageURL : photo,
      };

      const data = await updateBusiness(userData);
      await dispatch(SET_NAME(data.businessName));
      handleCancel();
      toast.success("Business details updated");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <> 
      {admin || currentUser?.permissions?.grantPermissions ? (
        <>
          {isLoading && <Loader />}
          <div className="sign-up-businesss edit">
            <div className="sign-up-business-header">
              <h2>Edit Business Info</h2>
            </div>
            <div className="business-informations">
              <div className="company-logo">
                <div className="company-logo-icon">
                  <img
                    src={selectedImage ? selectedImage : photo}
                    alt="company logo"
                  />
                  <div
                    className="company-logo-icon-pencil"
                    onClick={handlePencilClick}
                  >
                    <img src={verify6} alt="company logo" />
                  </div>
                </div>
              </div>
              <h6 className="center-text">Accepts: PNG, JPEG and JPG only</h6>
            </div>

            <div className="sign-up-actions">
              <div className="sign-up-forms">
                <form onSubmit={(e) => register(e)}>
                  <input
                    type="file"
                    accept="image/*"
                    name="image"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  <h3>Personal Informations</h3>
                  <div className="sign-up-forms-1">
                    <div className="sign-up-form-field">
                      <div>
                        <label>First Name*</label>
                        <input
                          type="text"
                          placeholder="Will"
                          required
                          name="ownerFirstName"
                          value={ownerFirstName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Last Name*</label>
                        <input
                          type="text"
                          placeholder="Smith"
                          required
                          name="ownerLastName"
                          value={ownerLastName}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="sign-up-form-field">
                      <div>
                        <label>Email*</label>
                        <input
                          type="email"
                          placeholder="you@gmail.com"
                          required
                          name="ownerEmail"
                          value={ownerEmail}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="password-input">
                        <label>Password*</label>
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder="disabled"
                          required
                          name="ownerPassword"
                          disabled
                          value={ownerPassword}
                          onChange={handleInputChange}
                        />
                        <div
                          onClick={() => setShowPassword(!showPassword)}
                          className="show-password"
                        >
                          {!showPassword && <div className="cross-line"></div>}
                          <img src={show} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <h3>Business Informations</h3>
                  <div className="sign-up-forms-1">
                    <div className="sign-up-form-field">
                      <div>
                        <label>Business Name*</label>
                        <input
                          type="text"
                          placeholder="chemicals ltd"
                          required
                          name="businessName"
                          value={businessName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Business Email*</label>
                        <input
                          type="email"
                          placeholder="business@gmail.com"
                          required
                          name="businessEmail"
                          value={businessEmail}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="sign-up-form-field">
                      <div>
                        <label>Business Address*</label>
                        <input
                          type="text"
                          placeholder="landmark, street ..."
                          required
                          name="businessAddress"
                          value={businessAddress}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Business Phone*</label>
                        <input
                          type="text"
                          placeholder="+2348065109764"
                          required
                          name="businessPhone"
                          value={businessPhone}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="sign-up-form-field">
                      <div>
                        <label>Industry*</label>
                        <input
                          type="text"
                          placeholder="Technology"
                          required
                          name="industry"
                          value={industry}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Country*</label>
                        <input
                          type="text"
                          placeholder="Nigeria"
                          required
                          name="country"
                          value={country}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="edit-buttons">
                    <button type="submit" className="proceed edit-business-btn">
                      Update
                    </button>
                    <button
                      onClick={() => handleCancel()}
                      type="button"
                      className="cancel"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="business-profile-item stores">
          <h3>Unauthorized</h3>
        </div>
      )}
    </>
  );
};

export default EditBusiness;
