import { useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import plus from "../../../assets/home/plus-circle.svg";
import minus from "../../../assets/home/minus-circle.svg";
import "../custom.scss";

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <>
      <div className="text-icon" onClick={decoratedOnClick}>
        <p>{children}</p>
        <img src={isCurrentEventKey ? minus : plus} alt="faq" />
      </div>
    </>
  );
}

export default function SecurityFaq() {
  return (
    <Accordion>
      <Card className="custom-bootstrap-faq-header">
        <Card.Header className="custome-card-header">
          <ContextAwareToggle eventKey="0">
            How secure is my data with your software?
          </ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="faq-answers">
            We prioritize data security and use industry-standard encryption to
            protect your information. Regular backups are also performed to
            ensure data integrity.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className="custom-bootstrap-faq-header">
        <Card.Header className="custome-card-header">
          <ContextAwareToggle eventKey="1">
            Can I control who has access to my inventory data?
          </ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body className="faq-answers">
            Yes, you can set permissions and roles to control access to
            different parts of the software to whoever you add as a salesperson
            or managers.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className="custom-bootstrap-faq-header">
        <Card.Header className="custome-card-header">
          <ContextAwareToggle eventKey="2">
            What happens to my data if I cancel my subscription?
          </ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body className="faq-answers">
            You will have the ability to export your data before canceling your
            subscription. After cancellation, your data will be securely stored
            for a limited period before being permanently deleted.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}
