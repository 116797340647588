import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import "./account.css";
import BusinessProfile from "./accComponents/BusinessProfile";
import Subscription from "./accComponents/Subscription";
import ManageStores from "./accComponents/ManageStores";
import SalesList from "./accComponents/SalesList";
import SubscribePage from "./accComponents/SubscribePage";
import useRedirectLoggedOutUser from "../../customHook/useRedirectLoggedOutUser";

export default function Account() {
  const [activeRoute, setActiveRoute] = useState("business-profile");

  const { id } = useParams();

  console.log(id);

  useEffect(() => {
    setActiveRoute(id);
  }, [id]);

  return (
    <div className="business-profile">
      {activeRoute !== "subscribe" ? (
        <div className="profile-navigation">
          <ul>
            <li>
              <Link
                className={
                  activeRoute === "business-profile" ||
                  activeRoute === undefined
                    ? "active-profile-text"
                    : ""
                }
                to="/accounts/business-profile"
              >
                {" "}
                Business profile{" "}
              </Link>{" "}
            </li>
            <li>
              <Link
                className={
                  activeRoute === "subscription" ? "active-profile-text" : ""
                }
                to="/accounts/subscription"
              >
                Subscription
              </Link>
            </li>
            <li>
              <Link
                className={activeRoute === "store" ? "active-profile-text" : ""}
                to="/accounts/store"
              >
                Manage stores
              </Link>
            </li>
            <li>
              <Link
                className={activeRoute === "sales" ? "active-profile-text" : ""}
                to="/accounts/sales"
              >
                Salespersons list
              </Link>
            </li>
          </ul>
        </div>
      ) : null}

      {!activeRoute || activeRoute === undefined ? <BusinessProfile /> : null}
      {activeRoute === "business-profile" && <BusinessProfile />}
      {activeRoute === "subscription" && <Subscription />}
      {activeRoute === "store" && <ManageStores />}
      {activeRoute === "sales" && <SalesList />}
      {activeRoute === "subscribe" ? <SubscribePage /> : null}
    </div>
  );
}
