import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { forgotPassword, validateEmail } from "../../services/authService";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loader";
// import "./auth.css";

const Forgot = () => {
  const [email, setEmail] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  console.log(window.location.hostname);

  const forgot = async (e) => {
    e.preventDefault();
    if (!email || !businessEmail) {
      return toast.error("Please enter all fields");
    }

    if (!validateEmail(email) || !validateEmail(businessEmail)) {
      return toast.error("Please enter a valid email");
    }

    const userData = {
      businessEmail,
      email,
      url: window.location.hostname === "localhost" ? "http://"+window.location.hostname+":3000" : window.location.hostname
    };

    setIsLoading(true);
    try {
      await forgotPassword(userData);
      setIsLoading(false);
      navigate("/forgot/confirm");
      setEmail("");
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="container auth">
      {isLoading && <Loader />}
      <div className="">
        <div className="auth-header">
          <h1>Enter Your email address</h1>
        </div>
        <form onSubmit={forgot}>
          <div className="auth-inputs">
            <div className="input-field">
              <label>Business Email</label>
              <input
                type="email"
                placeholder="business@gmail.com"
                // required
                name="businessEmail"
                value={businessEmail}
                onChange={(e) => setBusinessEmail(e.target.value)}
              />
            </div>
            <div className="input-field">
              <label>Email Address</label>
              <input
                type="email"
                placeholder="you@gmail.com"
                // required
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button type="submit" className="--btn --btn-primary --btn-block">
              Get Reset Email
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Forgot;
