import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductList from "../../components/product/productList/ProductList";
import useRedirectLoggedOutUser from "../../customHook/useRedirectLoggedOutUser";
import {
  selectIsLoggedIn,
  selectLoggedInBusinessOwner,
  selectUser,
} from "../../redux/features/auth/authSlice";
import {
  getProducts,
  getSales,
  getOutOfStock,
  getAllProductGroups,
} from "../../redux/features/product/productSlice";
import { useParams } from "react-router-dom";
import BusinessSummary from "../../components/businessSummary/businessSummary";
import { getCart } from "../../redux/features/cart/cartSlice";

const Dashboard = () => {
  const dispatch = useDispatch();
  const admin = useSelector(selectLoggedInBusinessOwner);

  const { id } = useParams();

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { products, isLoading, isError, message } = useSelector(
    (state) => state.product
  );

  const { allProductGroups } = useSelector((state) => state.allProductGroups);

  const user = useSelector(selectUser);

  useEffect(() => {
    if (isLoggedIn === true) {
      dispatch(getProducts());
    }

    if (isLoggedIn === true) {
      dispatch(getAllProductGroups());
    }

    if (isLoggedIn === true) {
      dispatch(getCart(user?.email));
    }

    if (isError) {
      console.log(message);
    }
  }, [isLoggedIn, isError, message, dispatch]);

  return (
    <div>
      <BusinessSummary
        products={products}
        productGroups={allProductGroups}
        admin={admin}
        user={user}
      />
    </div>
  );
};

export default Dashboard;
