import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCart,
} from "../../redux/features/cart/cartSlice";
import "./cart.css";
import {
  selectIsLoggedIn,
  selectUser,
} from "../../redux/features/auth/authSlice";
import CartDetails from "../../components/cartDetails/CartDetails";

export default function Cart() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(selectUser);

  const userCheckingOut = {
    name: user.name,
    email: user.email,
  };

 
  const { cart, isCartLoading } = useSelector((state) => state.cart);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getCart(user.email));
    }
  }, [dispatch, isLoggedIn]);

  return (
    <div>
      <CartDetails cart={cart} isLoading={isCartLoading} user={userCheckingOut} />
    </div>
  );
}
