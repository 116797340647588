import React, { useEffect, useState } from "react";
import editIcon from "../../../assets/home/edit-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_BUSINESS,
  selectBusiness,
  selectBusinessAddress,
  selectLoggedInBusinessOwner,
  selectName,
  selectUser,
} from "../../../redux/features/auth/authSlice";
import { getBusiness } from "../../../services/authService";
import EditBusiness from "./EditBusiness";

export default function BusinessProfile() {
  const dispatch = useDispatch();
  const business = useSelector(selectBusiness);
  const name = useSelector(selectName);
  const businessAddress = useSelector(selectBusinessAddress);
  const currentUser = useSelector(selectUser);
  const admin = useSelector(selectLoggedInBusinessOwner);
  const [editBusinessModal, setEditBusinessModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getBusiness();

      dispatch(SET_BUSINESS(data));
    };
    fetchData();
  }, [dispatch, getBusiness]);

  return (
    <div className="business-profile-item">
      {editBusinessModal && (
        <div className="edit-business-modal">
          <EditBusiness business={business} handleCancel={() => setEditBusinessModal(!editBusinessModal)} />
        </div>
      )}
      <div className="">
        <h3>Business profile</h3>
        <div className="item-container">
          <div className="item-img-name">
            <img src={business?.photo} alt="profile" />
            <div>
              <h2>{name}</h2>
              <h5>{business?.industry}</h5>
            </div>
          </div>
          {admin || currentUser?.permissions?.grantPermissions ? (
            <div onClick={() => setEditBusinessModal(!editBusinessModal)} className="item-action">
              <label htmlFor="">Edit</label>
              <img src={editIcon} alt="edit-icon" />
            </div>
          ) : null}
        </div>
      </div>
      <div className="">
        <h3>Address</h3>
        <div className="item-container">
          <div className="item-img-name">
            <div>
              <h2>{businessAddress}</h2>
              <h5>{business?.country}</h5>
            </div>
          </div>
          {admin || currentUser?.permissions?.grantPermissions ? (
            <div onClick={() => setEditBusinessModal(!editBusinessModal)} className="item-action">
              <label htmlFor="">Edit</label>
              <img src={editIcon} alt="edit-icon" />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
