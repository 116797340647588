import React, { useState } from "react";
import { RiProductHuntLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import "remixicon/fonts/remixicon.css";
import "./home.css";
import heroImg from "../../assets/inv-img.png";
import growth1 from "../../assets/home/Growth 1.svg";
import growth2 from "../../assets/home/Growth 2.svg";
import growth3 from "../../assets/home/Growth 3.svg";
import check from "../../assets/home/checkmark-circle-02.svg";
import connect from "../../assets/home/connect.svg";
import quote from "../../assets/home/quote.svg";
import testimonial from "../../assets/home/Frame 40.svg";
import osaks from "../../assets/home/osaks.jpg";
import simple from "../../assets/home/simple.jpg";
import stars from "../../assets/home/stars.svg";
import Header from "./homeComponents/Header";
import GeneralFaq from "./homeComponents/GeneralFaq";
import SecurityFaq from "./homeComponents/SecurityFaq";
import FeesFaq from "./homeComponents/FeesFaq";
import Footer from "./homeComponents/Footer";

const Home = () => {
  const [videoSrc, setVideoSrc] = useState("");

  const playVideo = () => {
    setVideoSrc(
      "https://www.youtube.com/embed/h2vIQdUaXLA?si=SFWSVlycBWtlVNwi&autoplay=1"
    );
  };

  const testimonies = [
    {
      name: "Bimpe Osakuade",
      position: "CEO, EYOB Agrochemicals",
      testimony:
        "The app has been very helpful in book keeping since all the items in my shop are well captured. I could know which products are completely sold out. It also help in reminding me the cost price at any given time.",
      image: testimonial,
    },
  ];

  return (
    <>
      <Header />

      <div className="home">
        {/* HERO SECTION */}
        <section className="container hero">
          <div className="hero-text">
            <h6>Your best bet in management</h6>
            <h1>
              Manage and Track various aspect of{" "}
              <span className="inventory">Inventory</span>{" "}
            </h1>
            <p>
              Inventory system to control and manage proucts in the warehouse in
              real time and integrated to make it easier to develop your
              business.
            </p>
            <div className="hero-buttons">
              <button className="--btn --btn-primary">
                <Link to="/register">Get a free trial</Link>
              </button>
              <button className="--btn --btn-secondary">
                <Link to="/contact-us">Contact Us</Link>
              </button>
            </div>
          </div>

          <div className="hero-image">
            <iframe
              src="https://www.youtube.com/embed/h2vIQdUaXLA?si=SFWSVlycBWtlVNwi"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
            {/* <div className="hero-image-overlay">
              <button>
                Watch the Demo video <i class="ri-play-circle-line"></i>
              </button>
            </div> */}
          </div>
        </section>

        {/* SPECIAL FEATURE */}
        <section className="special-feature">
          <div className="feature-header">
            <p>Our Special Feature</p>
            <h2>Know more about our key features</h2>
          </div>
          <div className="special-feature-cards">
            <div className="special-feature-card">
              <img src={growth3} alt="key features" />
              <h3>Growth</h3>
              <p>
                Manage all your products in one place. Add, update, delete and
                view all your products.
              </p>
            </div>
            <div className="special-feature-card">
              <img src={growth2} alt="key features" />
              <h3>Focus</h3>
              <p>
                Manage all your inventory in one place. Add, update, delete and
                view all your inventory.
              </p>
            </div>
            <div className="special-feature-card">
              <img src={growth1} alt="key features" />
              <h3>Security</h3>
              <p>
                Manage all your orders in one place. Add, update, delete and
                view all your orders.
              </p>
            </div>
          </div>
        </section>

        {/* SPECIAL PLANS */}

        <section className="special-plans">
          <div className="plans-text">
            <h3>How we work</h3>
            <h2>We have various plans</h2>
            <p>
              Our subscription plans are designed to empower you with the right
              tools and resources, ensuring that your journey with us is
              tailored to your specific requirements.{" "}
            </p>
            <button className="--btn --btn-primary">
              <Link to="/register">Learn more</Link>
            </button>
          </div>
          <div className="plans-image">
            <span className="plan basic">
              {" "}
              <img src={check} alt="plans" />
              Basic Plan
            </span>
            <span className="plan standard">
              {" "}
              <img src={check} alt="plans" />
              Standard Plan
            </span>
            <span className="plan pro">
              {" "}
              <img src={check} alt="plans" />
              Professional Plan
            </span>
          </div>
        </section>
      </div>
      {/* CONNECT SECTION */}

      <section className="connect">
        <div className="connect-image">
          <img src={connect} alt="connect with us" />
        </div>
        <div className="connect-text">
          <h2>Connecting the dots in inventory</h2>
          <p>
            Our subscription plans are designed to empower you with the right
            tools and resources, ensuring that your journey with us is tailored
            to your specific requirements.
          </p>
          <button className="--btn --btn-primary">
            <Link to="/contact-us">Learn more</Link>
          </button>
        </div>
      </section>

      {/* TESTIMONIALS SECTION */}
      <section className="testimonials">
        <div className="testimonials-header">
          <p>Testimonials</p>
          <h2>See our user's comments</h2>
        </div>
        <div className="testimonial-body">
          <div className="testimonials-cards">
            <div className="testimonials-card card-1">
              <div className="testimonials-card-header">
                <img src={osaks} alt="testimonial" />
                <h3>Mrs. Bimpe Osakuade</h3>
              </div>
              <p>
                <img src={quote} alt="quote" />
                <br />
                "The app has been very helpful in book keeping since all the
                items in my shop are well captured. I could know which products
                are completely sold out. It also help in reminding me the cost
                price at any given time."
              </p>
              <img src={stars} alt="stars" />
            </div>
            <div className="testimonials-card card-2">
              <div className="testimonials-card-header">
                <img src={simple} alt="testimonial" />
                <h3>Mr. Seun Omolaye</h3>
              </div>
              <p>
                <img src={quote} alt="quote" />
                <br />
                "This website has been so helpful in record keeping and acct
                balancing I have bn using it for more than 2 months with no error.
                Its a website that worth to be used. Well done guys!!!"
              </p>
              <img src={stars} alt="stars" />
            </div>
          </div>
          <div className="testimonials-cards single">
            <div className="testimonials-card card-3">
              <div className="testimonials-card-header">
                <img src={testimonial} alt="testimonial" />
                <h3>Jane Doe</h3>
              </div>
              <p>
                <img src={quote} alt="quote" />
                <br />
                "I have been using this app for a while now and I must say it
                has been a great experience. It has helped me manage my
                inventory effectively and efficiently."
              </p>
              <img src={stars} alt="stars" />
            </div>
          </div>
        </div>
      </section>

      {/* FREQUENTLY ASKED QUESTIONS */}
      <section className="faq">
        <div className="faq-header">
          <h2>Frequently Asked Questions</h2>
        </div>
        <div className="faq-body">
          <div className="faq-questions">
            <div className="faq-question">
              <div>
                <h3>General</h3>
                <p>Questions about the product</p>
              </div>
              <GeneralFaq />
            </div>
            <div className="faq-question">
              <div>
                <h3>Security</h3>
                <p>Questions about the security and data protection</p>
              </div>
              <SecurityFaq />
            </div>
            <div className="faq-question">
              <div>
                <h3>Fees</h3>
                <p>Questions about the charges that apply</p>
              </div>
              <FeesFaq />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Home;
