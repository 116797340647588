import { useEffect, useState } from "react";
import "./customers.css";
import { useDispatch, useSelector } from "react-redux";
import {
  FILTER_CUSTOMERS,
  selectFilteredCustomers,
} from "../../redux/features/product/filterSlice";
import ReactPaginate from "react-paginate";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import sendIcon from "../../assets/mobileIcons/send-icon-mobile.svg";
import deleteIcon from "../../assets/home/delete-icon.svg";
import { SpinnerImg } from "../../components/loader/Loader";
import Search from "../../components/search/Search";
import { RxCrossCircled } from "react-icons/rx";
import { selectLoggedInBusinessOwner } from "../../redux/features/auth/authSlice";
import { getCustomers } from "../../redux/features/cart/cartSlice";

const Customers = ({}) => {
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const filteredCustomers = useSelector(selectFilteredCustomers);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [receipients, setReceipients] = useState([]);
  const [checkedState, setCheckedState] = useState({});
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [showMessageTypeDialog, setShowMessageTypeDialog] = useState(false);
  const [messageType, setMessageType] = useState(""); // Email or SMS

  const admin = useSelector(selectLoggedInBusinessOwner);
  const { customers } = useSelector((state) => state.customers);

  const dispatch = useDispatch();

  const shortenText = (text, n) => {
    if (text.length > n) {
      const shortenedText = text.substring(0, n).concat("...");
      return shortenedText;
    }
    return text;
  };

  const handleMessageTypeSelection = (type) => {
    setMessageType(type);
    setShowMessageTypeDialog(false);
    setShowMessageModal(true);
  };

  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    // Create a Set to track unique customers
    const uniqueCustomers = new Set();
    const uniqueItems = filteredCustomers.filter((customer) => {
      // const customer = sale.customer;
      const customerIdentifier = `${customer.phone}-${customer.email}`;
      if (uniqueCustomers.has(customerIdentifier)) {
        return false;
      } else {
        uniqueCustomers.add(customerIdentifier);
        return true;
      }
    });

    setCurrentItems(uniqueItems.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(uniqueItems.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, filteredCustomers]);

  useEffect(() => {
    dispatch(FILTER_CUSTOMERS({ customers, search }));
  }, [customers, search, dispatch]);

  useEffect(() => {
    const isEveryChecked = currentItems.every(
      (customer) => checkedState[customer.phone]
    );
    const isNoneChecked = currentItems.every(
      (customer) => !checkedState[customer.phone]
    );

    setIsAllSelected(isEveryChecked && !isNoneChecked);
  }, [currentItems, checkedState]);

  const handleChecked = (e, customer) => {
    const { checked } = e.target;

    setCheckedState((prev) => ({
      ...prev,
      [customer.phone]: checked,
    }));

    if (checked) {
      setReceipients((prevReceipients) => [...prevReceipients, customer]);
    } else {
      setReceipients((prevReceipients) =>
        prevReceipients.filter(
          (receipient) => receipient.phone !== customer.phone
        )
      );
    }

    const isEveryChecked = currentItems.every((cust) =>
      cust.phone === customer.phone ? checked : checkedState[cust.phone]
    );
    setIsAllSelected(isEveryChecked);
  };

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    setIsAllSelected(checked);

    const updatedCheckedState = {};
    const updatedReceipients = [];

    if (checked) {
      customers.forEach((customer) => {
        updatedCheckedState[customer.phone] = true;
        updatedReceipients.push(customer);
      });
    } else {
      customers.forEach((customer) => {
        updatedCheckedState[customer.phone] = false;
      });
    }

    setCheckedState(updatedCheckedState);
    setReceipients(updatedReceipients);
  };

  console.log("receipients", receipients);

  const handleSendMessage = (e) => {
    e.preventDefault();
    console.log("sending message to", receipients);
  };

  return (
    <div className="product-list customers-list">
      <div className="table">
        <button
          className="mobile_send_button"
          onClick={() => setShowMessageTypeDialog(true)}
        >
          <img className="mobile_send_icon" src={sendIcon} alt="send-icon" />
        </button>
        <div className="--flex-between --flex-dir-column defaul-inventory-header">
          <span>
            <h3>
              <Link className="inventory-routes active-route" to="/inventory">
                All customers
              </Link>
            </h3>
          </span>
          <div className="contact-search">
            {/* <button onClick={() => setShowMessageModal(!showMessageModal)}>
              <img
                className="mobile_send_icon"
                src={sendIcon}
                alt="send-icon"
              />{" "}
              <span className="mobile_send_text">Contact</span>
            </button> */}
            <button onClick={() => setShowMessageTypeDialog(true)}>
              {/* <img
                className="mobile_send_icon"
                src={sendIcon}
                alt="send-icon"
              /> */}
              <span className="mobile_send_text">Contact</span>
            </button>
            <span>
              <Search
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </span>
          </div>
          {showMessageTypeDialog && (
            <div className="message-type-overlay">
              <div className="message-type-dialog">
                <RxCrossCircled
                  size={36}
                  className="cancel_message_dialogue"
                  onClick={() => setShowMessageTypeDialog(false)}
                />
                <h2>Select Message Type</h2>
                <div className="message-type-options">
                  <button onClick={() => handleMessageTypeSelection("Email")}>
                    Send Email
                  </button>
                  <button onClick={() => handleMessageTypeSelection("SMS")}>
                    Send SMS
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* {showMessageModal && (
            <div className="customers-message-container">
              <RxCrossCircled
                size={46}
                style={{ cursor: "pointer" }}
                className="cancel-modal"
                onClick={() => setShowMessageModal(!showMessageModal)}
              />
              <div className="message-body">
                <h1>Send message to customers</h1>
                <form onSubmit={handleSendMessage}>
                  <div className="customers-message-form">
                    <div className="form-field">
                      <label htmlFor="">Receipient(s)</label>
                      <input
                        type="text"
                        name="recipients"
                        id="recipients"
                        value={
                          receipients.length > 3
                            ? `${receipients[0]?.name}, ${
                                receipients[1]?.name
                              }, and ${receipients.length - 2} others`
                            : receipients
                                .map((recipient) => recipient.name)
                                .join(", ")
                        }
                        readOnly
                      />
                    </div>
                    <div className="form-field">
                      <label htmlFor="">Subject</label>
                      <input type="text" name="" id="" />
                    </div>
                    <div className="form-field">
                      <label htmlFor="">Message</label>
                      <textarea name="" id="" cols="15" rows="5"></textarea>
                    </div>
                  </div>
                  <div className="submit-btn">
                    <button type="submit">Send message</button>
                  </div>
                </form>
              </div>
            </div>
          )} */}
          {showMessageModal && (
            <div className="customers-message-container">
              <RxCrossCircled
                size={46}
                style={{ cursor: "pointer" }}
                className="cancel-modal"
                onClick={() => setShowMessageModal(false)}
              />
              <div className="message-body">
                <h1>{`Send ${messageType} to customers`}</h1>
                <form onSubmit={handleSendMessage}>
                  <div className="customers-message-form">
                    <div className="form-field">
                      <label htmlFor="">Recipient(s)</label>
                      <input
                        type="text"
                        name="recipients"
                        id="recipients"
                        value={
                          receipients.length > 3
                            ? `${receipients[0]?.name}, ${
                                receipients[1]?.name
                              }, and ${receipients.length - 2} others`
                            : receipients
                                .map((recipient) => recipient.name)
                                .join(", ")
                        }
                        readOnly
                      />
                    </div>
                    <div className="form-field">
                      <label htmlFor="">Subject</label>
                      <input
                        type="text"
                        name="subject"
                        id="subject"
                        placeholder={`Enter ${messageType.toLowerCase()} subject`}
                      />
                    </div>
                    <div className="form-field">
                      <label htmlFor="">Message</label>
                      <textarea
                        name="message"
                        id="message"
                        cols="15"
                        rows="5"
                        placeholder={`Write your ${messageType.toLowerCase()} message`}
                      ></textarea>
                    </div>
                  </div>
                  <div className="submit-btn">
                    <button type="submit">Send {messageType}</button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>

        {isLoading && <SpinnerImg />}

        <div className="table">
          {isLoading ? (
            <p>-- All customers</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={isAllSelected}
                      onChange={handleSelectAll}
                    />
                  </th>
                  <th>s/n</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {currentItems.map((customer, index) => {
                  // const { _id, customer } = sale;
                  return (
                    <tr key={index}>
                      <td>
                        <input
                          onChange={(e) => handleChecked(e, customer)}
                          type="checkbox"
                          value={JSON.stringify({
                            name: customer.name,
                            email: customer.email,
                            phone: customer.phone,
                          })}
                          checked={checkedState[customer.phone] || false}
                          name=""
                          id=""
                        />
                      </td>
                      <td>{index + 1}</td>
                      <td>
                        <Tooltip title={customer.name}>
                          {shortenText(customer.name, 16)}
                        </Tooltip>
                      </td>
                      <td>{customer.email}</td>
                      <td>{customer.phone}</td>
                      <td className="icons">
                        <span>
                          <img src={deleteIcon} alt="delete" />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={(event) => setItemOffset(event.selected * itemsPerPage)}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="activePageClass"
        />
      </div>
    </div>
  );
};

export default Customers;
