import React from "react";
import logo from "../../../assets/Logo.svg";
import { Link, NavLink } from "react-router-dom";
import "./footer.css";
import copy from "../../../assets/home/copy.svg";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-items"> 
        <div className="footer-logo">
          <img src={logo} /> 
          <h1>GNLife Inventory</h1>
        </div>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/pricing">Pricing</Link>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
          <li>
            <Link to="/about">About Us</Link>
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
          <li>
            <Link to="/our-policy">Policy</Link>
          </li>
          <li>
            <Link to="/terms-and-agreement">Terms</Link>
          </li>
        </ul>
      </div>
      <div className="copy-right">
        <p>
          <img src={copy} alt="copyright" /> Copyright 2024 GNLife Tech Inventory. All rights reserved
        </p>
      </div>
    </footer>
  );
}
