import React, { useEffect } from "react";
import editIcon from "../../../assets/home/edit-icon.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBusiness,
  SET_BUSINESS,
} from "../../../redux/features/auth/authSlice";
import { getBusiness } from "../../../services/authService";

export default function Subscription() {
  const business = useSelector(selectBusiness);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getBusiness();

      dispatch(SET_BUSINESS(data));
    };

    fetchData();
  }, [dispatch, getBusiness]);

  const getNextDueDate = () => {
    const monthId = business?.subscription
      ? new Date(business.subscription.nextDueDate).getMonth()
      : null;
    const year = business?.subscription
      ? new Date(business.subscription.nextDueDate).getFullYear()
      : null;

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const dueDate = months[monthId] + ", " + year;
    return dueDate;
  };

  // console.log("business", business);
  return (
    <div className="business-profile-item">
      <div className="">
        <h3>Subscription</h3>
        <div className="item-container">
          <div className="item-name subscription-plan">
            <div>
              <h3>Current plan</h3>
              <h1>
                {business?.subscription
                  ? business.subscription.plan
                  : "Professional"}
              </h1>
            </div>
            <div>
              <h4>Next due date</h4>
              <h6>{getNextDueDate()}</h6>
            </div>
          </div>
          <div className="item-action upgrade">
            <Link to="/accounts/subscribe">Upgrade</Link>
          </div>
        </div>{" "}
        <br />
        <div className="item-container">
          <div className="item-name">
            <h3>
              Kindly pay your subscription fee to the following account and send
              proof of payment to 08065109764 on whatsapp for confirmation.
            </h3>
            {/* {business?.subscription
                ? business.subscription.paymentMethod
                : "Visa 1234"} */}
            <h3>Bank Name: Providus Bank</h3>
            <h3>Account Name: AFOLABI OPEYEMI</h3>
            <h3>Account Number: 6504236709</h3>
          </div>
          <div className="item-action">
            <Link to="/accounts/subscribe">Edit</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
