import React from "react";
import "./about.css";
import Header from "../Home/homeComponents/Header";
import Footer from "../Home/homeComponents/Footer";
import ourStory from "../../assets/home/our-story.svg";
import simg1 from "../../assets/home/simg1.svg";
import simg2 from "../../assets/home/simg2.svg";
import simg3 from "../../assets/home/simg3.svg";
import simg4 from "../../assets/home/simg4.svg";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <>
      <Header /> 
      <section className="about-page">
        <div className="container about-page-header">
          <h1>Welcome to GNLife Tech Network</h1>
          <p>
            We are a software development company, we build software for small
            and large scale businesses and we also mentor in the
            services that we render
          </p>
        </div>

        <div className="our-story">
          <div className="story-text-img">
            <h3>Our Story</h3>
            <p>
              We believe that every project is unique, and there's no
              one-size-fits-all solution. That's why we approach each
              undertaking with a blend of creativity, expertise, and a
              commitment to delivering tailored solutions that align perfectly
              with your business goals.
            </p>
          </div>
          <div className="story-img">
            <img src={ourStory} alt="our-story" />
          </div>
        </div>

        <div className="our-services">
          <div className="container service-header">
            <h3>Our Services</h3>
            <p>
              Discover how our expertise, innovation, and client-centric
              approach can transform your vision into reality. Explore our
              services below and embark on a journey towards digital excellence.
            </p>
          </div>
          <div className="service-img-text">
            <div className="service-text">
              <ol>
                <li>Web development</li>
                <li>Digital marketing</li>
                <li>Video editing</li>
                <li>Mobile app development</li>
                <li>Graphics design</li>
                <li>Data analysis</li>
                <li>UI/UX design</li>
                <li>Consultation</li>
                <li>Weekend trainings</li>
              </ol>
            </div>
            <div className="service-images">
              <img src={simg1} alt="software service" />
              <img src={simg2} alt="software service" />
              <img src={simg3} alt="software service" />
              <img src={simg4} alt="software service" />
            </div>
          </div>
        </div>

        <div className="container about-page-action">
          <p>
            Get to explore any of our services to address your unique needs and
            propel your businesses or organizations to a new height.
          </p>
          <button className="--btn --btn-primary">
            <Link to="/contact-us">contact us</Link>
          </button>
        </div>
      </section>
      <Footer />
    </>
  );
}
