import React from "react";
import { Link } from "react-router-dom";

export default function ManageStores() {
  return (
    <div className="business-profile-item stores">
      <div style={{textAlign: "center"}} className="">
        <h3>Coming Soon!</h3><br />
        <Link to="/admin">Admin</Link>
      </div>
    </div>
  );
}
