import React from "react";
import complete from "../../../assets/home/complete.svg";
import { Link } from "react-router-dom";
import "./success.css";

export default function SuccessRegistration({}) {
  return (
    <div className="business-reg-success">
        <div className="success-registration">
            <img src={complete} alt="registration completed" />
            <div className="success-msg">
                <h1>Registration Completed</h1>
                <p>You have completed your registration. Please Log in.</p>
            </div>
            <button>
                <Link className="complete-btn" to="/login">Login</Link>
            </button>
        </div>
    </div>
  );
}
