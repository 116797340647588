import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_BUSINESS,
  selectBusiness,
  selectLoggedInBusinessOwner,
  selectUser,
} from "../../../redux/features/auth/authSlice";
import { deleteSales, getBusiness } from "../../../services/authService";
import AddSales from "./AddSales";
import { confirmAlert } from "react-confirm-alert";
import Loader from "../../../components/loader/Loader";
import { toast } from "react-toastify";
import EditSales from "./EditSales";

export default function SalesList() {
  const dispatch = useDispatch();
  const business = useSelector(selectBusiness);
  const [newSalesModal, setNewSalesModal] = useState(false);
  const [editSalesModal, setEditSalesModal] = useState(false);
  const [editSaleIndex, setEditSaleIndex] = useState(null); 
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector(selectUser);
  const admin = useSelector(selectLoggedInBusinessOwner);


  const confirmDelete = (email) => {
    confirmAlert({
      title: "Delete sales person",
      message: "Are you sure you want to delete this person.",
      buttons: [
        {
          label: "Delete",
          onClick: async () => {
            setIsLoading(true);
            try {
              const data = await deleteSales({ email });
              const businessData = await getBusiness();
              await dispatch(SET_BUSINESS(businessData));
              if (data.message) {
                toast.error(data.message);
                setIsLoading(false);
                return;
              }
              toast.success("Sales rep deleted successfully!");
              setIsLoading(false);
            } catch (error) {
              setIsLoading(false);
            }
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getBusiness();

      dispatch(SET_BUSINESS(data));
    };

    fetchData();
  }, [dispatch, getBusiness]);

  return (
    <>
      {admin || currentUser?.permissions?.grantPermissions ? (
        <div className="business-profile-item">
          {isLoading && <Loader />}
          {newSalesModal && (
            <AddSales handleCancel={() => setNewSalesModal(!newSalesModal)} />
          )}
          <div className="add-sales-rep">
            <h3>Sales List ({business?.sales?.length})</h3>
            <button onClick={() => setNewSalesModal(!newSalesModal)}>
              Add
            </button>
          </div>
          {business?.sales?.map((sale, index) => {
            return (
              <div key={index} className="">
                {editSalesModal &&
                  editSaleIndex === index && (
                    <EditSales
                      sale={sale}
                      handleCancel={() => setEditSalesModal(false)}
                    />
                )}
                <div className="item-container">
                  <div className="item-img-name">
                    <div>
                      <h2>{sale.firstName + " " + sale.lastName}</h2>
                      <h5>{sale.email}</h5> <br />
                      <br />
                      <div className="sales-permissions">
                        <h3>Permissions</h3>
                        <tr>
                          <td>Add Products</td>
                          <td>
                            {sale.permissions?.addProducts ? "true" : "false"}
                          </td>
                        </tr>
                        <tr>
                          <td>Delete Products</td>
                          <td>
                            {sale.permissions?.deleteProducts
                              ? "true"
                              : "false"}
                          </td>
                        </tr>
                        <tr>
                          <td>Edit Products</td>
                          <td>
                            {sale.permissions?.editproducts ? "true" : "false"}
                          </td>
                        </tr>
                        <tr>
                          <td>Sell Products</td>
                          <td>
                            {sale.permissions.sellProducts ? "true" : "false"}
                          </td>
                        </tr>
                        <tr>
                          <td>Grant Permissions</td>
                          <td>
                            {sale.permissions.grantPermissions
                              ? "true"
                              : "false"}
                          </td>
                        </tr>
                        <tr>
                          <td>See Business Finances</td>
                          <td>
                            {sale.permissions?.seeBusinessFinances
                              ? "true"
                              : "false"}
                          </td>
                        </tr>
                      </div>
                    </div>
                  </div>
                  <div className="item-action-container">
                    <div className="item-action">
                      <label
                        onClick={() => {
                          setEditSalesModal(true);
                          setEditSaleIndex(index);
                        }}
                        htmlFor=""
                      >
                        Edit
                      </label>
                    </div>
                    <label onClick={() => confirmDelete(sale.email)} htmlFor="">
                      Delete
                    </label>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="business-profile-item stores">
            <h3>Unauthorized</h3>
        </div>
      )}
    </>
  );
}
