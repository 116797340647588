import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchIncompletePayments,
  selectIncompletePayments,
  updateIncompletePayment,
} from "../../redux/features/cart/cartSlice";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import FulfilmentHeader from "../../components/inventoryHeader/FulfilmentHeader";
import { useParams } from "react-router-dom";
import "./fulfilment.css";
import {
  FILTER_INCOMPLETE_PAYMENTS,
  selectFilteredFulfilments,
} from "../../redux/features/product/filterSlice";
import FulFilmentTable from "./FulfilmentTable";

const Fulfilment = ({ isLoading }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [search, setSearch] = useState("");
  const incompletePayments = useSelector(selectIncompletePayments);
  const filteredFulfilments = useSelector(selectFilteredFulfilments);
  const [amountPaid, setAmountPaid] = useState({});

  // States for pending payments
  const [pendingItems, setPendingItems] = useState([]);
  const [pendingPageCount, setPendingPageCount] = useState(0);
  const [pendingItemOffset, setPendingItemOffset] = useState(0);
  const [pendingTotalItems, setPendingTotalItems] = useState(0);

  // States for completed payments
  const [completedItems, setCompletedItems] = useState([]);
  const [completedPageCount, setCompletedPageCount] = useState(0);
  const [completedItemOffset, setCompletedItemOffset] = useState(0);
  const [completedTotalItems, setCompletedTotalItems] = useState(0);

  const itemsPerPage = 10;

  useEffect(() => {
    dispatch(fetchIncompletePayments());
  }, [dispatch]);

  useEffect(() => {
    const pendingPayments = filteredFulfilments.filter(
      (item) =>
        item?.payment?.paymentType === "part" &&
        item?.payment?.paymentStatus === "pending"
    );

    const completedPayments = filteredFulfilments.filter(
      (item) =>
        item?.payment?.paymentType === "part" &&
        item?.payment?.paymentStatus === "completed"
    );

    setPendingTotalItems(pendingPayments.length);
    setCompletedTotalItems(completedPayments.length);

    const pendingEndOffset = pendingItemOffset + itemsPerPage;
    setPendingItems(pendingPayments.slice(pendingItemOffset, pendingEndOffset));
    setPendingPageCount(Math.ceil(pendingPayments.length / itemsPerPage));

    const completedEndOffset = completedItemOffset + itemsPerPage;
    setCompletedItems(
      completedPayments.slice(completedItemOffset, completedEndOffset)
    );
    setCompletedPageCount(Math.ceil(completedPayments.length / itemsPerPage));
  }, [
    filteredFulfilments,
    pendingItemOffset,
    completedItemOffset,
    itemsPerPage,
  ]);

  useEffect(() => {
    dispatch(FILTER_INCOMPLETE_PAYMENTS({ incompletePayments, search }));
  }, [incompletePayments, search, dispatch]);

  const handlePendingPageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % pendingTotalItems;
    setPendingItemOffset(newOffset);
  };

  const handleCompletedPageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % completedTotalItems;
    setCompletedItemOffset(newOffset);
  };

  const handleAmountChange = (id, value) => {
    setAmountPaid({
      ...amountPaid,
      [id]: value,
    });
  };

  const handleUpdatePayment = (id) => {
    const paymentUpdate = {
      id,
      amountPaid: Number(amountPaid[id]),
    };

    setAmountPaid((prevAmountPaid) => ({
      ...prevAmountPaid,
      [id]: "",
    }));

    if (paymentUpdate.amountPaid && paymentUpdate.amountPaid > 0) {
      dispatch(updateIncompletePayment(paymentUpdate)).then((result) => {
        if (updateIncompletePayment.fulfilled.match(result)) {
          toast.success("Payment updated successfully.");
          setAmountPaid((prevAmountPaid) => ({
            ...prevAmountPaid,
            [id]: "",
          }));
        } else {
          toast.error("Failed to update payment.");
        }
      });
    } else {
      toast.error("Please enter an amount.");
    }
  };

  return (
    <>
      <div className="product-list fulfilment_page">
        <div className="table">
          <FulfilmentHeader
            search={search}
            handleSearchChange={(e) => setSearch(e.target.value)}
          />
          {!id && (
            <>
              <div>
                {!isLoading && pendingItems.length === 0 ? (
                  <p className="no-products-p">
                    -- No pending payments found --
                  </p>
                ) : (
                  <FulFilmentTable
                    handleUpdatePayment={handleUpdatePayment}
                    amountPaid={amountPaid}
                    handleAmountChange={handleAmountChange}
                    items={pendingItems}
                  />
                )}
              </div>
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePendingPageClick}
                pageRangeDisplayed={3}
                pageCount={pendingPageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                previousLinkClassName="page-num"
                nextLinkClassName="page-num"
                activeLinkClassName="activePageClass"
              />
            </>
          )}

          {id && id === "cleared" && (
            <>
              <div>
                {!isLoading && completedItems.length === 0 ? (
                  <p className="no-products-p">
                    -- No completed payments found --
                  </p>
                ) : (
                  <FulFilmentTable
                    handleUpdatePayment={handleUpdatePayment}
                    amountPaid={amountPaid}
                    handleAmountChange={handleAmountChange}
                    items={completedItems}
                  />
                )}
              </div>
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handleCompletedPageClick}
                pageRangeDisplayed={3}
                pageCount={completedPageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                previousLinkClassName="page-num"
                nextLinkClassName="page-num"
                activeLinkClassName="activePageClass"
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Fulfilment;
