import React from "react";
import styles from "./Search.module.scss";
import searchIcon from "../../assets/home/search-icon.svg";


const Search = ({ value, onChange }) => {
  return (
    <div className={styles.search}>
      <img src={searchIcon} className={styles.icon} />
      <input
        type="text" 
        placeholder="Search products"
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default Search;
