import React, { useEffect, useRef, useState } from "react";
import dateIcon from "../../assets/home/dateIcon.svg";
import arrowDown from "../../assets/home/arrowdown.svg";
import DatePicker from "../datePicker/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  CALC_PROFIT_BY_PART_PENDING,
  CALC_PROFIT_VALUE,
  CALC_SALES_BY_CASH,
  CALC_SALES_BY_PART_PENDING,
  CALC_SALES_BY_POS,
  CALC_SALES_BY_TRANSFER,
  CALC_SOLD_VALUE,
  getCheckouts,
  selectCartProfitValue,
  selectCartSoldValue,
  selectPendingProfitValue,
  selectPendingSalesValue,
  selectTotalCash,
  selectTotalPOS,
  selectTotalTransfer,
} from "../../redux/features/cart/cartSlice";
import "./stats.css";
import leftArrow from "../../assets/home/narrowleft.svg"; // Add your left arrow icon here
import rightArrow from "../../assets/home/narrowright.svg"; // Add your right arrow icon here

const StatisticsHeader = ({ currentUser, checkouts, admin }) => {
  const totalSales = useSelector(selectCartSoldValue);
  const totalProfit = useSelector(selectCartProfitValue);
  const totalCash = useSelector(selectTotalCash);
  const totalTransfer = useSelector(selectTotalTransfer);
  const totalOnPOS = useSelector(selectTotalPOS);
  const totalPendingValue = useSelector(selectPendingSalesValue);
  const totalPendingProfit = useSelector(selectPendingProfitValue);
  const dispatch = useDispatch();
  const [displayQuery, setDisplayQuery] = useState(false);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const dateInputRef = useRef(null);
  const statisticsRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState("thisMonth");

  const formatter = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    }).format(amount);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;

    const selectedDate = new Date(value);

    if (name === "start") {
      selectedDate.setHours(0, 0, 0, 0);
      setStart(selectedDate.toISOString());
    }

    if (name === "end") {
      selectedDate.setHours(23, 59, 59, 999);
      setEnd(selectedDate.toISOString());
    }
  };

  useEffect(() => {
    dispatch(CALC_SOLD_VALUE(checkouts));
    dispatch(CALC_PROFIT_VALUE(checkouts));
    dispatch(CALC_SALES_BY_CASH(checkouts));
    dispatch(CALC_SALES_BY_TRANSFER(checkouts));
    dispatch(CALC_SALES_BY_POS(checkouts));
    dispatch(CALC_SALES_BY_PART_PENDING(checkouts));
    dispatch(CALC_PROFIT_BY_PART_PENDING(checkouts));
  }, [dispatch, checkouts]);

  const handleFetchSales = (start, end) => {
    dispatch(getCheckouts({ start, end }));
    setDisplayQuery(!displayQuery);
  };

  const handleSetQueryToTodayAndFetchSales = () => {
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0); // Start of today

    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999); // End of today

    setStart(todayStart.toISOString());
    setEnd(todayEnd.toISOString());
    setSelectedDate("today");
    dispatch(
      getCheckouts({
        start: todayStart.toISOString(),
        end: todayEnd.toISOString(),
      })
    );
    setDisplayQuery(!displayQuery);
  };

  const handleSetQueryToYesterdayAndFetchSales = () => {
    const today = new Date();

    const yesterdayStart = new Date(today);
    yesterdayStart.setDate(today.getDate() - 1);
    yesterdayStart.setHours(0, 0, 0, 0); // Start of yesterday

    const yesterdayEnd = new Date(today);
    yesterdayEnd.setDate(today.getDate() - 1);
    yesterdayEnd.setHours(23, 59, 59, 999); // End of yesterday

    setStart(yesterdayStart.toISOString());
    setEnd(yesterdayEnd.toISOString());
    setSelectedDate("yesterday");
    dispatch(
      getCheckouts({
        start: yesterdayStart.toISOString(),
        end: yesterdayEnd.toISOString(),
      })
    );
    setDisplayQuery(!displayQuery);
  };

  const handleSetQueryToThisWeekAndFetchSales = () => {
    const today = new Date();

    const weekStart = new Date(today);
    weekStart.setDate(today.getDate() - today.getDay());
    weekStart.setHours(0, 0, 0, 0); // Start of the week (Sunday)

    const weekEnd = new Date(today);
    weekEnd.setHours(23, 59, 59, 999); // End of today (end of the week if today is Saturday)

    setStart(weekStart.toISOString());
    setEnd(weekEnd.toISOString());
    setSelectedDate("thisWeek");
    dispatch(
      getCheckouts({
        start: weekStart.toISOString(),
        end: weekEnd.toISOString(),
      })
    );
    setDisplayQuery(!displayQuery);
  };

  const handleSetQueryToThisMonthAndFetchSales = () => {
    const today = new Date();

    const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    monthStart.setHours(0, 0, 0, 0); // Start of the month

    const monthEnd = new Date(today);
    monthEnd.setHours(23, 59, 59, 999); // End of today (last day of the month if today is the last day)

    setStart(monthStart.toISOString());
    setEnd(monthEnd.toISOString());
    setSelectedDate("thisMonth");
    dispatch(
      getCheckouts({
        start: monthStart.toISOString(),
        end: monthEnd.toISOString(),
      })
    );
    setDisplayQuery(!displayQuery);
  };

  const handleDisplayQuery = () => {
    setDisplayQuery(!displayQuery);
  };

  const scrollLeft = () => {
    statisticsRef.current.scrollBy({
      top: 0,
      left: -200, // Adjust the scroll distance as needed
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    statisticsRef.current.scrollBy({
      top: 0,
      left: 200, // Adjust the scroll distance as needed
      behavior: "smooth",
    });
  };

  return (
    <div className="statistics-container">
      <div className="statistics" ref={statisticsRef}>
        <div className="query-container">
          <div className="query" onClick={handleDisplayQuery}>
            <img src={dateIcon} alt="date" />
            <span>Sort by date</span>
            <img src={arrowDown} alt="arrow" />
          </div>
          <div
            className={displayQuery ? "query-cells show-query" : "query-cells"}
          >
            <div className="dates_selection">
              <div className="from">
                <label>From</label>
                <div>
                  <DatePicker
                    dateInputRef={dateInputRef}
                    name="start"
                    handleDateChange={handleDateChange}
                  />
                </div>
              </div>
              <div className="dash"></div>
              <div className="to">
                <label>To</label>
                <div>
                  <DatePicker
                    dateInputRef={dateInputRef}
                    name="end"
                    handleDateChange={handleDateChange}
                  />
                </div>
              </div>
            </div>
            <button
              onClick={() => handleFetchSales(start, end)}
              className="setDate"
            >
              Set Date
            </button>
            <div className="specific_selection">
              <h1
                className={selectedDate === "today" ? "selected_date" : ""}
                onClick={handleSetQueryToTodayAndFetchSales}
              >
                Today
              </h1>
              <h1
                className={selectedDate === "yesterday" ? "selected_date" : ""}
                onClick={handleSetQueryToYesterdayAndFetchSales}
              >
                Yesterday
              </h1>
              <h1
                className={selectedDate === "thisWeek" ? "selected_date" : ""}
                onClick={handleSetQueryToThisWeekAndFetchSales}
              >
                This Week
              </h1>
              <h1
                className={selectedDate === "thisMonth" ? "selected_date" : ""}
                onClick={handleSetQueryToThisMonthAndFetchSales}
              >
                This Month
              </h1>
            </div>
          </div>
        </div>

        <button className="scroll-button left" onClick={scrollLeft}>
          <img src={leftArrow} alt="Scroll Left" />
        </button>

        <div className="sales-informations t-sales">
          <div>
            <span>Total Sales</span>{" "}
            <h1>
              {admin || currentUser?.permissions?.seeBusinessFinances
                ? formatter(totalSales)
                : "Unavailable"}
            </h1>
          </div>
        </div>

        <div className="sales-informations t-profit">
          <div>
            <span>Total Profit</span>
            <h1>
              {admin || currentUser?.permissions?.seeBusinessFinances
                ? formatter(totalProfit)
                : "Unavailable"}
            </h1>
          </div>
        </div>
        <div className="sales-informations t-profit">
          <div>
            <span>Cash</span>
            <h1>
              {admin || currentUser?.permissions?.seeBusinessFinances
                ? formatter(totalCash)
                : "Unavailable"}
            </h1>
          </div>
        </div>
        <div className="sales-informations t-profit">
          <div>
            <span>Transfer</span>
            <h1>
              {admin || currentUser?.permissions?.seeBusinessFinances
                ? formatter(totalTransfer)
                : "Unavailable"}
            </h1>
          </div>
        </div>
        <div className="sales-informations t-profit">
          <div>
            <span>POS</span>
            <h1>
              {admin || currentUser?.permissions?.seeBusinessFinances
                ? formatter(totalOnPOS)
                : "Unavailable"}
            </h1>
          </div>
        </div>
        <div className="sales-informations t-profit">
          <div>
            <span>Pending Sales</span>
            <h1>
              {admin || currentUser?.permissions?.seeBusinessFinances
                ? formatter(totalPendingValue)
                : "Unavailable"}
            </h1>
          </div>
        </div>
        <div className="sales-informations t-profit">
          <div>
            <span>Pending profit</span>
            <h1>
              {admin || currentUser?.permissions?.seeBusinessFinances
                ? formatter(totalPendingProfit)
                : "Unavailable"}
            </h1>
          </div>
        </div>
      </div>
      <button className="scroll-button right" onClick={scrollRight}>
        <img src={rightArrow} alt="Scroll Right" />
      </button>
    </div>
  );
};

export default StatisticsHeader;
