import { Link } from "react-router-dom";

const ExpiredSubscription = () => {
  return (
    <>
      <div className="update_payment_container">
        <h1>
          Please update your payment{" "}
          <span>
            <Link to="/accounts/subscription">here</Link>
          </span>
        </h1>
      </div>
    </>
  );
};

export const GracePeriod = () => {
  return (
    <>
      <div className="grace_period_container">
        <h5>
          Your subscription is in grace period, kindly update your payment{" "}
          <Link to="/accounts/subscription">here</Link>
        </h5>
      </div>
      <br />
    </>
  );
};

export default ExpiredSubscription;
