import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import {
  getProduct,
  getProducts,
  selectIsLoading,
  selectProduct,
  updateProduct,
} from "../../redux/features/product/productSlice";
import ProductForm from "../../components/productForm/ProductForm";

const initialState = {
  name: "",
  category: "",
  quantity: "",
  price: "",
  warehouse: "",
  cost: "",
  sku: "",
  description: "",
};

const EditProduct = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { products, isLoading } = useSelector((state) => state.product);

  const [product, setProduct] = useState(null);
  const [description, setDescription] = useState("");
  const [productData, setProductData] = useState(initialState);
  const [productImage, setProductImage] = useState("");
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const generateKSKU = (category) => {
    const letter = category.slice(0, 3).toUpperCase();
    const number = Date.now();
    const sku = letter + "-" + number;
    return sku;
  };

  useEffect(() => {
    const productToEdit = products?.find((product) => product._id === id);

    if (productToEdit) {
      setProduct(productToEdit);
      setProductData(productToEdit);
    }
  }, [products, id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
    setProductData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    setProductImage(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  const saveProduct = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", productData.name);
    formData.append("sku", generateKSKU(productData.category));
    formData.append("category", productData.category);
    formData.append("quantity", Number(productData.quantity));
    formData.append("warehouse", productData.warehouse)
    formData.append("price", productData.price);
    formData.append("cost", productData.cost);
    formData.append("description", productData.description);
    formData.append("image", productImage);

    await dispatch(updateProduct({ id, formData }));
    await dispatch(getProducts());
    navigate("/inventory");
  };

  return (
    <div>
      {isLoading && <Loader />}
      <ProductForm
        product={product}
        setDescription={setDescription}
        description={description}
        handleInputChange={handleInputChange}
        saveProduct={saveProduct}
        handleImageChange={handleImageChange}
        productImage={productImage}
        imagePreview={imagePreview}
      />
    </div>
  );
};

export default EditProduct;
